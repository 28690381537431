import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  HStack,
  Heading,
  IconButton,
  Text,
  Input,
  Skeleton,
  Progress,
} from "@chakra-ui/react";

import { PageHeader } from "../../components/page-header";
import { ArrowLeftIcon, DownloadIcon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useVMRSDecodeOrder } from "../../api/endpoints/vmrs";
import "./index.css";
import { useEffect, useState } from "react";
import { DataTable } from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import CsvDownloader from "react-csv-downloader";

type PartIdentificationRecommendation = {
  system: string;
  assembly: string;
  component: string;
  code: string;
};

type VMRSCodeRecommendation = {
  parts: PartIdentificationRecommendation[];
  customerNotes: string;
};

type VMRSPartConversionResult = {
  partName: string;
  externalId: string;
  vmrsPartName: string;
  vmrsPartId: string;
  isFetching: boolean;
};

export const VMRSReportPage: React.FC = () => {
  const { id } = useParams();

  const {
    data: vmrsDecodeOrder,
    isPending: isFetchingVMRSDecodeOrder,
    isSuccess: isFetchVMRSDecodeOrderSuccess,
    refetch: refetchVMRSDecodeOrder,
  } = useVMRSDecodeOrder(id ?? "", id !== undefined);

  const navigate = useNavigate();
  const [conversionResults, setConversionResults] = useState<
    VMRSPartConversionResult[]
  >([]);

  const handleInputChange = (index: number, field: string, value: string) => {
    setConversionResults((prevResults) => {
      const newResults = [...prevResults];
      newResults[index] = {
        ...newResults[index],
        [field]: value,
      };
      return newResults;
    });
  };

  const columnHelper = createColumnHelper<VMRSPartConversionResult>();

  const columns = [
    columnHelper.accessor((row) => row.partName, {
      id: "partName",
      header: () => (
        <span>
          {vmrsDecodeOrder?.requestType === "Parts"
            ? "Part Name"
            : "Repair Notes"}
        </span>
      ),
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.externalId, {
      id: "externalId",
      header: () => (
        <span>
          {vmrsDecodeOrder?.requestType === "Parts" ? "Part ID" : "Task ID"}
        </span>
      ),
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.vmrsPartName, {
      id: "vmrsPartName",
      header: () => <span>VMRS Part Name</span>,
      cell: (info) => (
        <>
          <Skeleton
            startColor="gray.100"
            endColor="gray.300"
            isLoaded={!info.row.original.isFetching}
            fadeDuration={4}
          >
            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) =>
                handleInputChange(
                  info.row.index,
                  "vmrsPartName",
                  e.currentTarget.textContent || ""
                )
              }
            >
              {info.getValue() ?? "N/A"}
            </div>
          </Skeleton>
          {info.row.original.isFetching && (
            <Progress size="xs" isIndeterminate />
          )}
        </>
      ),
    }),
    columnHelper.accessor((row) => row.vmrsPartId, {
      id: "vmrsPartId",
      header: () => <span>VMRS Code</span>,
      cell: (info) => (
        <>
          <Skeleton
            startColor="gray.100"
            endColor="gray.300"
            isLoaded={!info.row.original.isFetching}
            fadeDuration={4}
          >
            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) =>
                handleInputChange(
                  info.row.index,
                  "vmrsPartId",
                  e.currentTarget.textContent || ""
                )
              }
            >
              {info.getValue() ?? "N/A"}
            </div>
          </Skeleton>
          {info.row.original.isFetching && (
            <Progress size="xs" isIndeterminate />
          )}
        </>
      ),
    }),
  ];

  useEffect(() => {
    if (vmrsDecodeOrder) {
      const results = vmrsDecodeOrder.orderLines.flatMap((orderLine) => {
        if (orderLine.status.toLowerCase() === "waiting") {
          return [
            {
              partName: orderLine.description,
              externalId: orderLine.externalId,
              vmrsPartName: "N/A",
              vmrsPartId: "N/A",
              isFetching: true,
            },
          ];
        } else {
          return orderLine.parts.map((part) => ({
            partName: orderLine.description,
            externalId: orderLine.externalId,
            vmrsPartName: part.component ?? "N/A",
            vmrsPartId: part.code ?? "N/A",
            isFetching: false,
          }));
        }
      });

      setConversionResults(results);
    }
  }, [vmrsDecodeOrder]);

  useEffect(() => {
    if (isFetchVMRSDecodeOrderSuccess) {
      let pollInterval: NodeJS.Timeout | null = null;

      if (
        vmrsDecodeOrder?.orderLines?.some(
          (line) => line.status.toLowerCase() === "waiting"
        )
      ) {
        pollInterval = setInterval(() => {
          refetchVMRSDecodeOrder();
        }, 5000);
      }

      if (
        vmrsDecodeOrder?.orderLines?.every(
          (line) => line.status === "completed"
        ) &&
        pollInterval
      ) {
        clearInterval(pollInterval);
      }

      return () => {
        if (pollInterval) {
          clearInterval(pollInterval);
        }
      };
    }
  }, [vmrsDecodeOrder, isFetchVMRSDecodeOrderSuccess]);

  return (
    <Box height={"110vh"} overflow={"scroll"}>
      <PageHeader title="VMRS AI Assistant"></PageHeader>
      <Card variant={"outline"} m={5} height={"100%"}>
        <CardBody>
          <Box>
            <HStack>
              <IconButton
                icon={<ArrowLeftIcon />}
                onClick={() => {
                  navigate("/vmrs");
                }}
                aria-label={"Back"}
              />
              <Heading size={"md"}>Converter</Heading>
            </HStack>
            <Divider color={"gray.400"} />
            <Text color={"fg.muted"} size={"10px"} fontWeight={"normal"}>
              Axle converted the columns you selected to VMRS codes. This system
              is still learning and data may not always be accurate. Please
              double check the data before downloading.
            </Text>
            <DataTable
              columns={columns}
              data={conversionResults}
              isSearchEnabled={true}
              actionChildren={
                <CsvDownloader
                  //@ts-ignore
                  datas={conversionResults}
                  filename={"conversion_results.csv"}
                  columns={[
                    {
                      displayName:
                        vmrsDecodeOrder?.requestType === "Parts"
                          ? "Part Name"
                          : "Description",
                      id: "partName",
                    },
                    {
                      displayName:
                        vmrsDecodeOrder?.requestType === "Parts"
                          ? "Part ID"
                          : "Task ID",
                      id: "externalId",
                    },
                    { displayName: "VMRS Part Name", id: "vmrsPartName" },
                    { displayName: "VMRS Code", id: "vmrsPartId" },
                  ]}
                >
                  <Button
                    width={{ base: "100%", md: "auto" }}
                    leftIcon={<DownloadIcon size={".875rem"} />}
                    variant="outline"
                  >
                    Download CSV
                  </Button>
                </CsvDownloader>
              }
            ></DataTable>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};
