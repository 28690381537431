import { Box, Stack } from "@chakra-ui/layout";
import { Tabs, TabList, Tab, TabIndicator } from "@chakra-ui/tabs";
import React, { useEffect, useState } from "react";
import { OrganizationTab } from "./organization-tab";
import { useAuth } from "@clerk/clerk-react";
import { TabPanels, TabPanel, Heading } from "@chakra-ui/react";
import { PageHeader } from "../../components/page-header";

export const SettingsPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState<
    { name: string; component: React.ReactNode }[]
  >([]);

  const { orgRole } = useAuth();

  useEffect(() => {
    if (orgRole === "admin") {
      setTabs([
        ...tabs,
        {
          name: "Organization",
          component: <OrganizationTab key={2} />,
        },
        { name: "Billing", component: <></> },
      ]);
    }
  }, [orgRole]);

  return (
    <Box overflow={"scroll"} height={"100vh"}>
      <PageHeader title="Organization Settings"></PageHeader>
      <Stack>
        <Tabs size={"lg"} variant="soft-rounded">
          <TabList
            pb={6}
            px={6}
            background={"white"}
            borderBottom={"1px"}
            borderBottomColor={"gray.100"}
          >
            {tabs.map((tab, index) => {
              return <Tab key={index}>{tab.name}</Tab>;
            })}
          </TabList>
          <TabIndicator />
          <TabPanels>
            {tabs.map((tab, index) => {
              return <TabPanel key={index}>{tab.component}</TabPanel>;
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
};
