import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  useOrganizationMetadata,
  useUpdateOrganizationMetadata,
} from "../../api/endpoints/organizations";
import { OrganizationMetadata } from "../../types-new";
import { Select } from "chakra-react-select";
import { useCurricula } from "../../api/endpoints/courses";

export const OrganizationComplianceSection: React.FC = () => {
  const orgnizationDetailsEditSchema = Yup.object().shape({
    isDrayageFleet: Yup.boolean(),
    isStateGovernment: Yup.boolean(),
    isLocalGovernment: Yup.boolean(),
    isFederalGovernment: Yup.boolean(),
    isHighPriorityFleet: Yup.boolean(),
    businessStates: Yup.array().of(Yup.string()),
  });

  const { mutate: updateOrganizationMetadata } =
    useUpdateOrganizationMetadata();
  const {
    data: curricula,
    isError,
    isLoading: curriculaLoading,
  } = useCurricula();

  const { data: organizationMetadata, isLoading: organizationMetadataLoading } =
    useOrganizationMetadata();

  const initialValues = {
    isDrayageFleet: false,
    isStateGovernment: false,
    isLocalGovernment: false,
    isFederalGovernment: false,
    isHighPriorityFleet: false,
    businessStates: [],
    isFleet: false,
    defaultTrainingPlans: [],
  };

  const curriculaOptions = curricula?.map((curriculum) => ({
    label: curriculum.name,
    value: curriculum.id,
  }));

  const onSubmit = async (values: any) => {
    console.log(values);
    await updateOrganizationMetadata({
      isDrayageFleet: values.isDrayageFleet,
      isStateGovernment: values.isStateGovernment,
      isLocalGovernment: values.isLocalGovernment,
      isFederalGovernment: values.isFederalGovernment,
      isHighPriorityFleet: values.isHighPriorityFleet,
      businessStates: values.businessStates,
      isFleet: values.isFleet,
      defaultTrainingPlans: values.defaultTrainingPlans,
    } as OrganizationMetadata);
  };

  const usStateOptions = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  return (
    <Card
      direction={{ base: "row", sm: "row" }}
      variant="outline"
      my={{ base: "4", md: "8" }}
    >
      <Stack width={"100%"}>
        <Formik
          initialValues={organizationMetadata ?? initialValues}
          validationSchema={orgnizationDetailsEditSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, handleChange, touched, setFieldValue }) => (
            <Form className="p-4 text-center">
              <CardBody>
                <Heading size="md">Fleet Information</Heading>
                <Text color={"fg.muted"}>
                  The information provided here will be used to determine the
                  appropriate compliance requirements for your fleet.
                </Text>
                <Stack
                  spacing="5"
                  px={{ base: "2", md: "3" }}
                  py={{ base: "2", md: "3" }}
                >
                  <Stack color="brand.text">
                    <FormControl
                      isInvalid={!!errors.isFleet && !!touched.isFleet}
                    >
                      <Checkbox
                        colorScheme="green"
                        name="isFleet"
                        isChecked={values.isFleet}
                        onChange={(e) => {
                          setFieldValue("isFleet", e.target.checked);

                          if (e.target.checked) {
                            setFieldValue("isDrayageFleet", false);
                            setFieldValue("isStateGovernment", false);
                            setFieldValue("isLocalGovernment", false);
                            setFieldValue("isFederalGovernment", false);
                            setFieldValue("isHighPriorityFleet", false);
                          }
                        }}
                      >
                        You operate a motor vehicle fleet.
                      </Checkbox>
                      <FormErrorMessage>
                        <ErrorMessage name="isFleet" />
                      </FormErrorMessage>
                    </FormControl>
                    {values.isFleet && (
                      <>
                        <FormControl
                          isInvalid={
                            !!errors.isDrayageFleet && !!touched.isDrayageFleet
                          }
                        >
                          <Checkbox
                            colorScheme="green"
                            name="isDrayageFleet"
                            isChecked={values.isDrayageFleet}
                            onChange={handleChange}
                          >
                            Your fleet is used for drayage purposes.
                          </Checkbox>
                          <FormErrorMessage>
                            <ErrorMessage name="isDrayageFleet" />
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.isFederalGovernment &&
                            !!touched.isFederalGovernment
                          }
                        >
                          <Checkbox
                            colorScheme="green"
                            name="isFederalGovernment"
                            isChecked={values.isFederalGovernment}
                            onChange={handleChange}
                          >
                            You operate a federal government fleet.
                          </Checkbox>
                          <FormErrorMessage>
                            <ErrorMessage name="isFederalGovernment" />
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.isStateGovernment &&
                            !!touched.isStateGovernment
                          }
                        >
                          <Checkbox
                            colorScheme="green"
                            name="isStateGovernment"
                            isChecked={values.isStateGovernment}
                            onChange={handleChange}
                          >
                            You operate a state government fleet.
                          </Checkbox>
                          <FormErrorMessage>
                            <ErrorMessage name="isStateGovernment" />
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.isLocalGovernment &&
                            !!touched.isLocalGovernment
                          }
                        >
                          <Checkbox
                            colorScheme="green"
                            name="isLocalGovernment"
                            isChecked={values.isLocalGovernment}
                            onChange={handleChange}
                          >
                            You operate a municipal, city, or county government
                            fleet.
                          </Checkbox>
                          <FormErrorMessage>
                            <ErrorMessage name="isLocalGovernment" />
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.isHighPriorityFleet &&
                            !!touched.isHighPriorityFleet
                          }
                        >
                          <Checkbox
                            colorScheme="green"
                            name="isHighPriorityFleet"
                            isChecked={values.isHighPriorityFleet}
                            onChange={handleChange}
                          >
                            Your fleet has a gross annual revenue of $50 million
                            or more OR you direct the operation / own 50 or more
                            vehicles.
                          </Checkbox>
                          <FormErrorMessage>
                            <ErrorMessage name="isHighPriorityFleet" />
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.businessStates && !!touched.businessStates
                          }
                          mt={5}
                        >
                          <FormLabel>States of Business</FormLabel>
                          <Select
                            options={usStateOptions}
                            placeholder={"States of Business"}
                            isMulti
                            name="businessStates"
                            chakraStyles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "50%",
                              }),
                            }}
                            value={values.businessStates.map((state) =>
                              usStateOptions.find((x) => x.value === state)
                            )}
                            onChange={(e: any) => {
                              setFieldValue(
                                "businessStates",
                                e.map((x: any) => x.value)
                              );
                            }}
                          />
                          <FormHelperText>
                            Select all the states where your fleet operates.
                          </FormHelperText>
                          <FormErrorMessage>
                            <ErrorMessage name="businessStates" />
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                  </Stack>
                </Stack>
                <Heading size="md">Training</Heading>
                <Text color={"fg.muted"}>
                  These settings modify training for your organization.
                </Text>
                <Stack
                  spacing="5"
                  px={{ base: "2", md: "3" }}
                  py={{ base: "2", md: "3" }}
                >
                  <Stack color="brand.text">
                    <FormControl
                      isInvalid={
                        !!errors.defaultTrainingPlans &&
                        !!touched.defaultTrainingPlans
                      }
                      mt={5}
                    >
                      <FormLabel>Training Plans</FormLabel>
                      <Select
                        options={curriculaOptions}
                        placeholder={"Default Training Plans"}
                        isMulti
                        name="defaultTrainingPlans"
                        chakraStyles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "50%",
                          }),
                        }}
                        value={values.defaultTrainingPlans?.map(
                          (trainingPlan) =>
                            curriculaOptions?.find(
                              (x) => x.value === trainingPlan
                            )
                        )}
                        onChange={(e: any) => {
                          setFieldValue(
                            "defaultTrainingPlans",
                            e.map((x: any) => x.value)
                          );
                        }}
                      />
                      <FormHelperText>
                        Select any training plans that should automatically be
                        assigned to new organization members.
                      </FormHelperText>
                      <FormErrorMessage>
                        <ErrorMessage name="defaultTrainingPlans" />
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Stack>
              </CardBody>
              <CardFooter justifyContent={"right"} pt={0}>
                <Button
                  variant="outline"
                  bg="brand.primary.500"
                  color={"brand.textLight"}
                  _hover={{ backgroundColor: "brand.primary.500" }}
                  type="submit"
                >
                  Save
                </Button>
              </CardFooter>
            </Form>
          )}
        </Formik>
      </Stack>
    </Card>
  );
};
