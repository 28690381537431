import { Button, ButtonProps, IconButton } from "@chakra-ui/button";
import "react-toastify/dist/ReactToastify.css";
import {
  Flex,
  Stack,
  Box,
  Image,
  HStack,
  Text,
  FlexProps,
  Img,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
  useMenuButton,
  Center,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerProps,
  IconButtonProps,
  chakra,
  DrawerCloseButton,
} from "@chakra-ui/react";
import {
  BiBuilding,
  BiBuildings,
  BiBuoy,
  BiMenu,
  BiShield,
  BiSolidGraduation,
} from "react-icons/bi";
import { BsCardChecklist, BsChatDots, BsShop } from "react-icons/bs";
import { Outlet } from "react-router";
import { NavItem, NavSubItem } from "../components/nav-item";
import {
  OrganizationSwitcher,
  SignOutButton,
  useAuth,
  useOrganization,
  useOrganizationList,
  useUser,
} from "@clerk/clerk-react";
import { HiDocument, HiSelector } from "react-icons/hi";
import type { UserResource } from "@clerk/types";
import { Link } from "react-router-dom";
import { Home, Settings } from "lucide-react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useHubspotChat } from "../hooks/use-hubspot-chat";
import { Collapse, Icon } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { IoDocument, IoDocumentsOutline } from "react-icons/io5";

export const SiteOperationsCollapse = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box>
      <Button
        variant="tertiary"
        onClick={onToggle}
        justifyContent="space-between"
        width="full"
      >
        <HStack spacing="3">
          <Icon as={BiBuilding} />
          <Text as="span">Site Operations</Text>
        </HStack>
        <PopoverIcon isOpen={isOpen} />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing="1" alignItems="stretch" ps="8" py="1">
          {["Resumes", "Cover Letter", "Personal", "Education"].map((item) => (
            <Button key={item} variant="tertiary" justifyContent="start">
              {item}
            </Button>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export const PopoverIcon = (props: { isOpen: boolean }) => {
  const iconStyles = {
    transform: props.isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};

export const SidebarButton = (props: ButtonProps) => (
  <Button
    variant="tertiary"
    justifyContent="start"
    iconSpacing="3"
    color={"brand.textLight"}
    _hover={{ backgroundColor: "#799386" }}
    {...props}
  />
);

type UserProps = {
  user: UserResource | null | undefined;
};

type AccountSwitcherButtonProps = FlexProps & UserProps;

const AccountSwitcherButton = (props: AccountSwitcherButtonProps) => {
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      userSelect="none"
      cursor="pointer"
      outline="0"
      color={"brand.text"}
      transition="all 0.2s"
      _focus={{ shadow: "outline" }}
    >
      <HStack flex="1" spacing="3">
        <Img
          w="8"
          h="8"
          rounded="full"
          objectFit="cover"
          src={props.user?.imageUrl}
        />
        <Box textAlign="start">
          <Text variant={"sm"} color={"brand.text"}>
            {props.user?.fullName}
          </Text>
        </Box>
      </HStack>
      <Box fontSize="lg" color={"brand.accent"}>
        <HiSelector />
      </Box>
    </Flex>
  );
};

const AccountSwitcher = ({
  user,
}: {
  user: UserResource | null | undefined;
}) => {
  const { orgRole } = useAuth();

  return (
    <Menu>
      <AccountSwitcherButton user={user} />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue("gray.600", "gray.200")}
        px="3"
      >
        <Text fontWeight="medium" mb="2">
          {user?.primaryEmailAddress?.emailAddress}
        </Text>
        <MenuDivider />
        <MenuItem rounded="md">
          <Link to={"/profile"}>Profile</Link>
        </MenuItem>
        {orgRole === "admin" && (
          <MenuItem rounded="md">
            <Link to={"/settings"}>Settings</Link>
          </MenuItem>
        )}
        <MenuItem rounded="md">
          <SignOutButton />
        </MenuItem>
        <MenuDivider />
        <OrganizationSwitcher hidePersonal={true} />
      </MenuList>
    </Menu>
  );
};

interface ToggleButtonProps extends IconButtonProps {
  isOpen: boolean;
}

export const ToggleButton = (props: ToggleButtonProps) => {
  const { isOpen, ...iconButtonProps } = props;
  return (
    <IconButton
      variant="unstyled"
      display="inline-flex"
      size="xs"
      icon={<ToggleIcon active={isOpen} />}
      {...iconButtonProps}
    />
  );
};

const ToggleIcon = (props: { active: boolean }) => {
  const { active } = props;
  return (
    <Box
      color="fg.muted"
      className="group"
      data-active={active ? "" : undefined}
      as="span"
      display="block"
      w="1.5rem"
      h="1.5rem"
      pos="relative"
      aria-hidden
      pointerEvents="none"
    >
      <Bar
        top="0.4375rem"
        _groupActive={{ top: "0.6875rem", transform: "rotate(45deg)" }}
      />
      <Bar
        bottom="0.4375rem"
        _groupActive={{ bottom: "0.6875rem", transform: "rotate(-45deg)" }}
      />
    </Box>
  );
};

const Bar = chakra("span", {
  baseStyle: {
    display: "block",
    pos: "absolute",
    w: "1.25rem",
    h: "0.125rem",
    rounded: "full",
    bg: "currentcolor",
    mx: "auto",
    insetStart: "0.125rem",
    transition: "all 0.12s",
  },
});

const Navigation = () => {
  const { orgRole } = useAuth();
  const { enableConnections, isDemoMode, enableDocuments, enableVmrs } =
    useFlags();
  const isAdmin = orgRole === "admin";

  return (
    <Stack flex="1" overflow="auto" pt="8">
      {isDemoMode && isAdmin && (
        <NavItem icon={<Home />} label="My Home">
          <NavSubItem label="Company Protocols" to={"/my/protocols"} />
          <NavSubItem label="Inspections" to={"/my/inspections"} />
          <NavSubItem label="Tasks" to={"/my/tasks"} />
        </NavItem>
      )}
      {isDemoMode && isAdmin && (
        <NavItem
          icon={<BsCardChecklist />}
          label="Foundation"
          to={"/foundation"}
        ></NavItem>
      )}
      {enableDocuments && (
        <NavItem
          icon={<IoDocumentsOutline />}
          label="Documents"
          to={"/documents"}
        />
      )}
      <NavItem
        icon={<BiSolidGraduation />}
        label="Training"
        to={"/my/courses"}
      />
      {isDemoMode && isAdmin && (
        <NavItem icon={<BiShield />} label="Safety & Compliance">
          <NavSubItem label="CARB Tracker" to={"/carb-tracker"} />
          <NavSubItem label="Playbooks" to={"/projects"} />
          <NavSubItem label="Emergency Guides" to={"/tools/emergency-guides"} />
          <NavSubItem
            label="Inspections"
            to={"/doc-library/inspection-templates"}
          />
          <NavSubItem label="Evidence" to={"/doc-library/evidence"} />
          <NavSubItem label="Protocols" to={"/doc-library/protocols"} />
        </NavItem>
      )}
      {isDemoMode && isAdmin && (
        <NavItem icon={<BiBuildings />} label="Site Operations">
          <NavSubItem label="Vehicles" to={"/vehicles"} />
          <NavSubItem label="PPE Management" to={"/tools/glove-management"} />
          <NavSubItem label="Battery Recycling" to={"/vehicles?type=battery"} />
        </NavItem>
      )}
      {isDemoMode && isAdmin && (
        <>
          <NavItem
            icon={<BsShop />}
            label="Vendor Integrations"
            to={"/vendor-integrations"}
          />
          <NavItem icon={<BsChatDots />} label="Community" to={"/community"} />
        </>
      )}
      {enableVmrs && isAdmin && (
        <NavItem icon={<BsShop />} label="VMRS Analysis" to={"/vmrs"} />
      )}
      {isAdmin && (
        <NavItem icon={<Settings />} label="Administration">
          <NavSubItem label="Training Plans" to={"/training"} />
          <NavSubItem label="Members" to={"/org-management/members"} />
          <NavSubItem label="Teams" to={"/org-management/teams"} />
          <NavSubItem label="Sites" to={"/org-management/sites"} />
          {enableConnections && (
            <NavSubItem label="Integrations" to={"/connections"} />
          )}
        </NavItem>
      )}
    </Stack>
  );
};

export const MobileDrawer = (
  props: Omit<DrawerProps, "children"> & { chatOpen: () => void }
) => {
  const { user } = useUser();

  return (
    <Drawer placement="top" {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody mt="16">
          <Stack spacing="6" align="stretch">
            <Box onClick={() => props.onClose()}>
              <Navigation />
            </Box>
            <Box my={1} onClick={props.chatOpen}>
              <NavItem subtle icon={<BiBuoy />} label="Help & Support" />
            </Box>
            <Box>
              <AccountSwitcher user={user} />
            </Box>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export const AppLayout = (): JSX.Element => {
  const { orgRole } = useAuth();
  const { user } = useUser();
  const { organization } = useOrganization();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();
  const ldClient = useLDClient();

  const { trialBanner, whitelabelConfiguration } = useFlags();

  useEffect(() => {
    if (organization && user) {
      ldClient?.identify(
        {
          kind: "multi",
          user: {
            key: user?.id,
            name: user?.fullName,
            email: user?.primaryEmailAddress?.emailAddress,
            role: orgRole,
          },
          organization: {
            key: organization?.id,
            name: organization?.name,
          },
        },
        undefined,
        (err, flags) => {}
      );
    } else if (user) {
      ldClient?.identify(
        {
          kind: "user",
          key: user?.id,
          name: user?.fullName ?? "",
          email: user?.primaryEmailAddress?.emailAddress,
          role: orgRole,
        },
        undefined,
        (err, flags) => {}
      );
    }
  }, [user, organization]);

  const { openHandler } = useHubspotChat("43602822");

  const { setActive } = useOrganizationList();

  const { orgId } = useAuth();

  useEffect(() => {
    if (orgId === null) {
      if (
        user?.organizationMemberships &&
        user?.organizationMemberships.length > 0
      ) {
        //@ts-ignore
        setActive({
          organization: user.organizationMemberships[0].organization.id,
        });
      }
    }
  }, [orgId, user]);

  return (
    <>
      {!isDesktop && (
        <Box position={"relative"} height={"100vh"}>
          <Box
            borderBottom={"1px"}
            borderColor={"gray.200"}
            zIndex="tooltip"
            position={"relative"}
            width={"100%"}
            p={0}
          >
            <HStack
              justify={"space-between"}
              width={"100%"}
              p={4}
              bg={"brand.surface"}
            >
              <Image
                src={whitelabelConfiguration?.logoUrl ?? "/logo.png"}
                width={"20%"}
              />
              <MobileDrawer
                colorScheme="green"
                isOpen={mobileNavbar.isOpen}
                onClose={mobileNavbar.onClose}
                chatOpen={openHandler}
              />
              <IconButton
                icon={<BiMenu size={"lg"} />}
                aria-label="menu"
                onClick={mobileNavbar.onToggle}
                color={"brand.text"}
              />
            </HStack>
            {trialBanner?.trim() !== "" && orgRole === "admin" && (
              <Box>
                <Box borderBottomWidth="1px" bg="red.500" color={"white"}>
                  <HStack justify={"center"}>
                    <Text color="white" fontWeight="medium">
                      Your trial expires on {trialBanner}. Please contact
                      support@axlemobility.com to extend your trial or pay your
                      invoice.
                    </Text>
                  </HStack>
                </Box>
              </Box>
            )}
          </Box>
          <Box position={"relative"} width={"100vw"} height={"100vh"}>
            <Outlet />
          </Box>
        </Box>
      )}
      {isDesktop && (
        <HStack overflow={"hidden"} height={"100vh"} spacing={0}>
          <Box
            w={"20%"}
            bg="brand.surface"
            color="brand.text"
            fontSize="sm"
            height={"100vh"}
          >
            <Flex h="full" direction="column" px="2" py="4">
              {/* <AccountSwitcher /> */}
              <Center>
                <Image
                  src={whitelabelConfiguration?.logoUrl ?? "/logo.png"}
                  width={"50%"}
                />
              </Center>
              <Navigation />
              <Box my={1} onClick={() => openHandler()}>
                <NavItem subtle icon={<BiBuoy />} label="Help & Support" />
              </Box>
              <Box>
                <AccountSwitcher user={user} />
              </Box>
            </Flex>
          </Box>
          <Box
            w={"100%"}
            height={"100vh"}
            bg={"brand.background"}
            overflowY={"auto"}
          >
            {trialBanner?.trim() !== "" && orgRole === "admin" && (
              <Box>
                <Box borderBottomWidth="1px" bg="red.500" color={"white"}>
                  <HStack justify={"center"}>
                    <Text color="white" fontWeight="medium">
                      Your trial expires on {trialBanner}. Please contact
                      support@axlemobility.com to extend your trial or pay your
                      invoice.
                    </Text>
                  </HStack>
                </Box>
              </Box>
            )}

            <Outlet />
          </Box>
        </HStack>
      )}
      <div id="zmmtg-root" style={{ zIndex: 9999 }}></div>
    </>
  );
};
