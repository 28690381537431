import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ClerkProvider } from "@clerk/clerk-react";
import { createContext } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

export const SettingsContext = createContext({
  zoomSdkKey: "",
  mapBoxAccessToken: "",
});

const SettingsProvider = ({
  children,
  settings,
}: {
  children: React.ReactNode;
  settings: any;
}) => {
  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

const queryClient = new QueryClient();

fetch("/api/internal/settings")
  .then((res) => res.json())
  .then(async (settings) => {
    const FeatureFlagProvider = await asyncWithLDProvider({
      clientSideID: settings?.launchDarklyClientKey ?? "",
      context: {
        kind: "user",
        anonymous: true,
      },
      options: {
        bootstrap: "localStorage",
      },
      reactOptions: {
        useCamelCaseFlagKeys: true,
      },
    });

    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(
      <SettingsProvider settings={settings}>
        <ClerkProvider publishableKey={settings.clerkPublishableKey}>
          <QueryClientProvider client={queryClient}>
            <FeatureFlagProvider>
              <ToastContainer
                position="bottom-center"
                autoClose={2500}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                transition={Slide}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              <BrowserRouter>
                <App settings={settings} />
              </BrowserRouter>
            </FeatureFlagProvider>
          </QueryClientProvider>
        </ClerkProvider>
      </SettingsProvider>
    );
  })
  .catch((error) => {
    console.error("Error bootstrapping application: ", error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
