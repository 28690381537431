import {
  Box,
  Stack,
  Card,
  CardBody,
  Heading,
  Badge,
  Tooltip,
  StackDivider,
  Button,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { CurriculumProgress } from "../../types";
import { useEffect, useState } from "react";
import { DataTable } from "../../components/table";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { useCurriculaAdminReport } from "../../api/endpoints/courses";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { HiInformationCircle } from "react-icons/hi";
import { CSVLink, CSVDownload } from "react-csv";

import { BoxProps, HStack, Text } from "@chakra-ui/react";

interface Props extends BoxProps {
  label: string;
  value: string;
  delta: {
    value: string;
    isUpwardsTrend: boolean;
  };
}

ChartJS.register(ArcElement, ChartTooltip, Legend);
dayjs.extend(relativeTime);

type UngroupedCurriculaProgressReport = {
  member?: { name: string; id: string };
  emailAddress?: string;
  progress?: {
    status?: string;
    completedAt?: string;
    curriculum: {
      id?: string;
      name: string;
    };
    validUntil?: string;
    isQualified?: boolean;
  };
  subRows?: UngroupedCurriculaProgressReport[];
};

export const CourseReportingTab: React.FC = () => {
  const { data: adminReport, isError, isLoading } = useCurriculaAdminReport();
  const [trainingPlanCompletionData, setTrainingPlanCompletionData] = useState<{
    complete: number;
    incomplete: number;
  }>();
  const [
    trainingPlanVsQualificationStatusPercentage,
    setTrainingPlanVsQualificationStatusPercentage,
  ] = useState<number>(0);

  const [tableData, setTableData] = useState<
    UngroupedCurriculaProgressReport[]
  >([]);
  const [csvData, setCsvData] = useState<any[]>([]);

  useEffect(() => {
    if (!adminReport) return;
    const ungroupedReports = [] as UngroupedCurriculaProgressReport[];

    for (const report of adminReport) {
      const ungroupedReport = {
        member: report.member,
        emailAddress: report.emailAddress,
        progress: { curriculum: { name: "" }, validUntil: "", completedAt: "" },
        subRows: [] as UngroupedCurriculaProgressReport[],
      };

      for (const progress of report.progresses) {
        ungroupedReport.subRows.push({
          member: { name: "", id: "" },
          emailAddress: "",
          progress: progress,
        });
      }

      ungroupedReports.push(ungroupedReport);
    }

    const flattenedReports = ungroupedReports.flatMap((report) => {
      return report?.subRows?.map((progress) => {
        return [
          report.member?.name,
          report.emailAddress,
          progress.progress?.curriculum.name,
          progress.progress?.status,
          progress.progress?.completedAt,
          progress.progress?.validUntil,
          progress.progress?.isQualified,
        ];
      });
    });

    setCsvData(flattenedReports);
    setTableData(ungroupedReports);
  }, [adminReport]);

  useEffect(() => {
    if (!adminReport) return;

    const processedData = {
      complete: 0,
      incomplete: 0,
    };

    for (const member of adminReport) {
      for (const progress of member.progresses) {
        if (progress.status === "Completed") {
          processedData.complete++;
        } else {
          processedData.incomplete++;
        }
      }
    }

    setTrainingPlanCompletionData(processedData);
  }, [adminReport]);

  useEffect(() => {
    if (!adminReport) return;

    const processedData = {
      completions: 0,
      qualifications: 0,
    };

    for (const member of adminReport) {
      for (const progress of member.progresses) {
        if (progress.status === "Completed" && progress.isQualified !== null) {
          processedData.completions++;
          if (progress.isQualified === true) {
            processedData.qualifications++;
          }
        }
      }
    }
    if (processedData.completions === 0) {
      setTrainingPlanVsQualificationStatusPercentage(0);
    } else {
      setTrainingPlanVsQualificationStatusPercentage(
        (processedData.qualifications / processedData.completions) * 100
      );
    }
  }, [adminReport]);

  const columns = React.useMemo<ColumnDef<UngroupedCurriculaProgressReport>[]>(
    () => [
      {
        accessorKey: "member.name",
        header: () => <Box>Assignee</Box>,
        cell: ({ row, getValue }) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
            }}
          >
            <>
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: "pointer" },
                  }}
                >
                  {getValue()?.toString()}
                </button>
              ) : (
                <Text>{getValue()?.toString()}</Text>
              )}
            </>
          </div>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row.progress?.curriculum.name,
        id: "Training Plan",
        cell: (info) => info.getValue(),
        header: () => <Box>Training Plan</Box>,
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row.progress?.status,
        id: "Progress",
        cell: (info) => {
          switch (info.row.original.progress?.status) {
            case "Completed":
              return <Badge variant={"green"}>Completed</Badge>;
            case "Expired":
              return <Badge variant={"red"}>Expired</Badge>;
            case "InProgress":
              return <Badge variant={"blue"}>In Progress</Badge>;
            case "NotStarted":
              return <Badge variant={"gray"}>Not Started</Badge>;
          }
        },
        header: () => <Box>Progress</Box>,
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row.progress?.curriculum.name,
        id: "Qualification",
        cell: (info) => {
          if (!info.row.getCanExpand()) {
            if (info.row.original.progress?.isQualified == undefined) {
              return <Text color={"gray.500"}>N/A</Text>;
            } else if (info.row.original.progress.isQualified == true) {
              return <Text>Yes</Text>;
            } else {
              return (
                <Text color={"red.500"} fontWeight={"bold"}>
                  No
                </Text>
              );
            }
          }
        },
        header: () => (
          <Tooltip
            label={
              "Some training plans require certification by an external organization. Members will need to upload proof of certification by that external organization to be considered qualified."
            }
          >
            <HStack>
              <Text>Qualification</Text>
              <HiInformationCircle />
            </HStack>
          </Tooltip>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row.progress?.completedAt,
        id: "Completed At",
        cell: (info) => {
          if (!info.row.getCanExpand()) {
            if (info.row.original.progress?.completedAt) {
              return new Date(info.row.original.progress?.completedAt)
                .toISOString()
                .split("T")[0];
            }

            return "N/A";
          }
        },
        header: () => <Box>Completed At</Box>,
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row.progress?.validUntil,
        id: "Valid Until",
        cell: (info) => {
          if (!info.row.getCanExpand()) {
            if (info.row.original.progress?.validUntil) {
              return new Date(info.row.original.progress?.validUntil)
                .toISOString()
                .split("T")[0];
            }

            return "N/A";
          }
        },
        header: () => <Box>Valid Until</Box>,
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  return (
    <Box height={"100vh"} overflow={"auto"} pt={5}>
      <Card variant={"outline"} mx={5}>
        <CardBody>
          <Heading size={"md"} as={"h3"}>
            Dashboard
          </Heading>

          <HStack width={"100%"} my={5}>
            <Card variant={"outline"} width={"50%"} height={"100%"}>
              <CardBody>
                <HStack
                  spacing={5}
                  divider={
                    <StackDivider color={"gray.500"} borderWidth={"1px"} />
                  }
                >
                  <Stack width={"40%"}>
                    <Heading as={"h4"} variant={"md"}>
                      Tasks Not Completed
                    </Heading>
                    <HStack spacing={0} mt={5}>
                      <Heading variant={"4xl"} color={"green.500"}>
                        {trainingPlanCompletionData?.incomplete}
                      </Heading>
                      <Heading
                        color={"gray.500"}
                        variant={"4xl"}
                        fontWeight={"300"}
                      >
                        /
                      </Heading>
                      <Heading
                        color={"gray.500"}
                        fontWeight={"300"}
                        variant={"4xl"}
                      >
                        {(trainingPlanCompletionData?.complete ?? 0) +
                          (trainingPlanCompletionData?.incomplete ?? 0)}
                      </Heading>
                    </HStack>
                  </Stack>
                  <Stack>
                    <Heading as={"h4"} variant={"md"}>
                      Task Completion Rate
                    </Heading>
                    <HStack spacing={0} mt={5}>
                      <Heading variant={"4xl"} color={"green.500"}>
                        {Math.round(
                          ((trainingPlanCompletionData?.complete ?? 0) /
                            ((trainingPlanCompletionData?.complete ?? 0) +
                              (trainingPlanCompletionData?.incomplete ?? 0))) *
                            100
                        )}
                        %
                      </Heading>
                    </HStack>
                  </Stack>
                </HStack>
              </CardBody>
            </Card>
            <Card variant={"outline"} width={"50%"} height={"100%"}>
              <CardBody>
                <Stack>
                  <Tooltip
                    label={
                      "The rate of members who have completed a training plan vs how many members have achieved a certification. Higher percentages mean members are successfully achieving their certification goals after completing the assigned training plans."
                    }
                  >
                    <Heading as={"h4"} variant={"md"}>
                      Qualification Completion Rate
                    </Heading>
                  </Tooltip>
                  <HStack mt={5} spacing={0}>
                    <Heading variant={"4xl"} color={"green.500"}>
                      {trainingPlanVsQualificationStatusPercentage}%
                    </Heading>
                  </HStack>
                </Stack>
              </CardBody>
            </Card>
          </HStack>
        </CardBody>
      </Card>
      <Box m={2} p={2}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={tableData ?? []}
              getSubRowsFn={(row) => row.subRows}
              initialState={{ expanded: true }}
              actionChildren={
                <CSVLink
                  data={csvData}
                  filename={`axle-training-plan-completion-report-${new Date().toISOString()}.csv`}
                  headers={[
                    "Assignee",
                    "Email Address",
                    "Training Plan",
                    "Progress",
                    "Completed At",
                    "Valid Until",
                    "Qualification",
                  ]}
                >
                  <Button variant={"outline"}>Download CSV</Button>
                </CSVLink>
              }
            />
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};
