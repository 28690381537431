import {
  Badge,
  Box,
  BoxProps,
  Button,
  Card,
  CardBody,
  HStack,
  Icon,
  IconButton,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth, useUser } from "@clerk/clerk-react";
import { createColumnHelper } from "@tanstack/react-table";
import { PlusIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCARBReport } from "../../api/endpoints/vehicles";
import { PageHeader } from "../../components/page-header";
import { DataTable } from "../../components/table";
import { CARBReportLine } from "../../types";
import {
  HiOutlineDocumentDownload,
  HiOutlineExclamationCircle,
} from "react-icons/hi";

interface Props extends BoxProps {
  label: string;
  value: string;
}

const Stat = (props: Props) => {
  const { label, value, ...boxProps } = props;
  return (
    <Box {...boxProps}>
      <Stack spacing={0}>
        <Text textStyle="xs" color="fg.muted" mb={2} fontWeight={"bold"}>
          {label}
        </Text>
        <Heading size={{ base: "sm", md: "md" }} {...boxProps}>
          {value}
        </Heading>
      </Stack>
    </Box>
  );
};

const NewRegulationsBanner: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <Box borderBottomWidth="1px" bg="blue.100" width={"100%"}>
      <Box py="3">
        <HStack
          spacing={{ base: "3", md: "4" }}
          justify={{ base: "start", md: "space-between" }}
          px={5}
        >
          <Box boxSize="8" display={{ base: "none", md: "block" }} />
          <Text color="fg.emphasized" fontWeight="medium">
            See historical emissions results.
          </Text>
          <Button variant={"solid"} colorScheme={"blue"} onClick={onClose}>
            Review
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};
export const CARBReportPage: React.FC = () => {
  const { orgRole } = useAuth();
  const { user } = useUser();
  const isAdmin = orgRole && orgRole === "admin";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const columnHelper = createColumnHelper<CARBReportLine>();
  const navigate = useNavigate();

  const { data: vehicles } = useCARBReport();

  const columns = [
    columnHelper.accessor((row) => row.vin, {
      id: "vin",
      header: () => <span>VIN</span>,
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.make, {
      id: "make",
      header: () => <span>Make</span>,
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.model, {
      id: "model",
      header: () => <span>Model</span>,
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.year, {
      id: "year",
      header: () => <span>Year</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.gvwr, {
      id: "gvwr",
      header: () => <span>GVWR</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.engineYear, {
      id: "engineYear",
      header: () => <span>Engine Year</span>,
      cell: (info) => {
        const value = info.getValue();
        return value === 0 ? "N/A" : value;
      },
    }),

    columnHelper.accessor((row) => row.odometer, {
      id: "odometer",
      header: () => <span>Mileage</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.nextInspectionDueDate, {
      id: "nextInspectionDueDate",
      header: () => <span>Next Inspection</span>,
      cell: (info) => {
        const date = new Date(info.getValue());
        return date.toLocaleDateString();
      },
    }),
    columnHelper.accessor((row) => row.isCompliant, {
      id: "isCompliant",
      header: () => <span>Compliant</span>,
      cell: (info) => {
        const isCompliant = info.getValue();
        return (
          <Badge variant={isCompliant ? "gray" : "orange"}>
            {isCompliant ? "Compliant" : "Non-Compliant"}
          </Badge>
        );
      },
    }),
    columnHelper.accessor((row) => row.complianceFrameworks, {
      id: "complianceFrameworks",
      header: () => <span>CARB</span>,
      cell: (info) => {
        return info.getValue().map((framework) => {
          return (
            <Badge
              mr={1}
              background={framework.isCompliant ? "green.100" : "orange.100"}
              color={framework.isCompliant ? "green.500" : "orange.500"}
              fontWeight={"400"}
            >
              {framework.name}
            </Badge>
          );
        });
      },
    }),
    columnHelper.accessor((row) => row.id, {
      id: "actions",
      header: "Last Report",
      cell: (info) => (
        <IconButton
          aria-label="Download Last Report"
          icon={<HiOutlineDocumentDownload />}
          size="sm"
          variant="ghost"
          onClick={() => {}}
        />
      ),
    }),
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="CARB Tracker"></PageHeader>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <Stack spacing={5}>
              <SimpleGrid columns={{ base: 1, md: 2 }} gap={5}>
                <Card variant={"outline"}>
                  <CardBody>
                    <Stack>
                      <SimpleGrid columns={3} gap={3}>
                        <Card variant={"outline"}>
                          <CardBody p={3}>
                            <Stat label="Total Fleet Vehicles" value="50" />
                          </CardBody>
                        </Card>
                        <Card variant={"outline"}>
                          <CardBody p={3}>
                            <Stat label="Total Inspected Vehicles" value="5" />
                          </CardBody>
                        </Card>
                        <Card variant={"outline"}>
                          <CardBody p={3}>
                            <Stat
                              label="Non-Compliant Vehicles"
                              value={"5"}
                              color="red.500"
                              fontWeight="bold"
                            />
                          </CardBody>
                        </Card>
                      </SimpleGrid>
                      <Card variant={"outline"}>
                        <CardBody p={3}>
                          <Stat
                            label="Next Inspection Due"
                            value={(() => {
                              const today = new Date();
                              const nextMonth = new Date(
                                today.getFullYear(),
                                today.getMonth() + 1,
                                1
                              );
                              // Find the first weekday (Monday to Friday) of the next month
                              while (
                                nextMonth.getDay() === 0 ||
                                nextMonth.getDay() === 6
                              ) {
                                nextMonth.setDate(nextMonth.getDate() + 1);
                              }
                              return nextMonth.toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              });
                            })()}
                          />
                        </CardBody>
                      </Card>
                    </Stack>
                  </CardBody>
                </Card>
                <Card variant={"outline"}>
                  <CardBody>
                    <Stack>
                      <Card variant={"outline"}>
                        <CardBody p={3}>
                          <Stat label="Total Emissions Reports" value="5" />
                        </CardBody>
                      </Card>
                      <SimpleGrid columns={2} gap={3}>
                        <Card variant={"outline"}>
                          <CardBody p={3}>
                            <Box>
                              <Stack spacing={0}>
                                <Text
                                  textStyle="xs"
                                  color="fg.muted"
                                  mb={2}
                                  fontWeight={"bold"}
                                >
                                  Emissions Records
                                </Text>
                                <HStack spacing={2} alignItems="center">
                                  <Heading
                                    size={{ base: "sm", md: "sm" }}
                                    color="orange.500"
                                  >
                                    Action Required
                                  </Heading>
                                  <Icon
                                    as={HiOutlineExclamationCircle}
                                    color="orange.500"
                                    boxSize={5}
                                  />
                                </HStack>
                              </Stack>
                            </Box>
                          </CardBody>
                        </Card>
                        <Card variant={"outline"}>
                          <CardBody p={3}>
                            <Box>
                              <Stack spacing={0}>
                                <Text
                                  textStyle="xs"
                                  color="fg.muted"
                                  mb={2}
                                  fontWeight={"bold"}
                                >
                                  Maintenance Records
                                </Text>
                                <HStack spacing={2} alignItems="center">
                                  <Heading
                                    size={{ base: "sm", md: "sm" }}
                                    color="orange.500"
                                  >
                                    Action Required
                                  </Heading>
                                  <Icon
                                    as={HiOutlineExclamationCircle}
                                    color="orange.500"
                                    boxSize={5}
                                  />
                                </HStack>
                              </Stack>
                            </Box>
                          </CardBody>
                        </Card>
                      </SimpleGrid>
                    </Stack>
                  </CardBody>
                </Card>
              </SimpleGrid>
              <NewRegulationsBanner onClose={() => {}} />
              <Card variant={"outline"}>
                <CardBody>
                  <DataTable
                    columns={columns}
                    data={vehicles ?? []}
                    actionChildren={
                      <Button
                        width={{ base: "100%", md: "auto" }}
                        leftIcon={<PlusIcon size={".875rem"} />}
                        variant="outline"
                        onClick={onOpen}
                      >
                        Request HD/IM Inspection
                      </Button>
                    }
                  ></DataTable>
                </CardBody>
              </Card>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Request HD/IM Inspection</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Enter your name"
                  value={user?.fullName ?? ""}
                  isDisabled
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={user?.emailAddresses[0].emailAddress ?? ""}
                  isDisabled
                />
              </FormControl>
              <FormControl>
                <FormLabel>Inspection Zip Code</FormLabel>
                <Input placeholder="Enter inspection zip code" type="number" />
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="inverted" mr={3} onClick={onClose}>
              Submit
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
