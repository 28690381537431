import {
  HStack,
  Heading,
  Button,
  Box,
  Badge,
  IconButton,
  Text,
  Stack,
  Grid,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MyCertificate } from "../../types";
import { PageHeader } from "../../components/page-header";
import { UserProfile } from "@clerk/clerk-react";
import { useMyCertificates, useMyCourses } from "../../api/endpoints/my";
import { BiDownload, BiSolidCertification } from "react-icons/bi";
import { ChevronRight } from "lucide-react";

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { data: courses } = useMyCourses();
  const { data: certificates } = useMyCertificates();

  return (
    <Box height={"100vh"}>
      <PageHeader title="Profile"></PageHeader>
      <Stack p={5} height={"100%"}>
        <Grid
          h="90%"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap={4}
        >
          <GridItem
            rowSpan={{ base: 2, md: 2 }}
            colSpan={{ base: 2, md: 1 }}
            background={"white"}
            overflowY={"scroll"}
          >
            <UserProfile
              appearance={{
                elements: {
                  navbar: {
                    display: "none",
                  },
                  formButtonPrimary: {
                    fontSize: 14,
                    textTransform: "none",
                    backgroundColor: "#204B36",
                    "&:hover, &:focus, &:active": {
                      backgroundColor: "#49247A",
                    },
                  },
                  footerActionLink: {
                    color: "#204B36",
                  },
                  card: {
                    boxShadow: "none",
                    background: "none",
                    width: "100%",
                  },
                },
                layout: {},
              }}
            ></UserProfile>
          </GridItem>
          <GridItem
            colSpan={{ base: 2, md: 1 }}
            rowSpan={{ base: 2, md: 1 }}
            p={7}
            pt={10}
            bg="white"
            overflowY={"scroll"}
          >
            <HStack width={"100%"} justify={"space-between"} mb={5}>
              <Heading>Courses</Heading>
              <Button
                rightIcon={<ChevronRight />}
                variant="solid"
                colorScheme="brand.primary"
                onClick={() => {
                  navigate("/my/courses");
                }}
                size={"sm"}
              >
                View More Details
              </Button>
            </HStack>
            {courses?.map((course, i) => {
              return (
                <Box
                  borderWidth={"1px"}
                  borderColor={"gray.300"}
                  borderRadius={"lg"}
                  p={5}
                  mb={5}
                >
                  <HStack
                    key={course.id}
                    justify={"space-between"}
                    width={"100%"}
                  >
                    <HStack>
                      <Text size={"md"}>
                        {i + 1}. {course.name}
                      </Text>
                    </HStack>
                    <HStack>
                      <Box>
                        {course.status === "NotStarted" && (
                          <Badge background={"red.500"} color={"white"}>
                            Not Started
                          </Badge>
                        )}
                        {course.status === "InProgress" && (
                          <Badge background={"yellow.500"} color={"white"}>
                            In Progress
                          </Badge>
                        )}
                        {course.status === "Completed" && (
                          <Badge background={"green.500"} color={"white"}>
                            Completed
                          </Badge>
                        )}
                        {course.status === "Expired" && (
                          <Badge background={"red.500"} color={"white"}>
                            Expired
                          </Badge>
                        )}
                      </Box>
                    </HStack>
                  </HStack>
                </Box>
              );
            })}
            {courses?.length === 0 && (
              <Text>You don't have any courses assigned right now.</Text>
            )}
          </GridItem>
          <GridItem
            colSpan={{ base: 2, md: 1 }}
            rowSpan={{ base: 2, md: 1 }}
            p={7}
            pt={10}
            bg="white"
            overflowY={"scroll"}
          >
            <HStack width={"100%"} justify={"space-between"} mb={5}>
              <Heading>Certificates</Heading>
              <Button
                rightIcon={<ChevronRight />}
                size={"sm"}
                variant="solid"
                colorScheme="brand.primary"
                onClick={() => {
                  navigate("/my/courses");
                }}
              >
                Manage Certificates
              </Button>
            </HStack>
            {certificates?.map((certificate: MyCertificate) => {
              return (
                <Stack my={6}>
                  <HStack justify={"space-between"}>
                    <Heading
                      size={"md"}
                      display={"flex"}
                      flexDir={"row"}
                      alignItems={"center"}
                    >
                      <BiSolidCertification />
                      <Text ml={2}>{certificate.name}</Text>
                    </Heading>
                    <HStack>
                      <IconButton
                        icon={<BiDownload />}
                        background={"brand.primary.500"}
                        aria-label="download-certificate"
                        color={"white"}
                        onClick={() => {
                          window.open(certificate.url, "_blank");
                        }}
                      />
                    </HStack>
                  </HStack>
                  <SimpleGrid columns={2}>
                    <Text color={"fg.muted"} fontStyle={"italic"}>
                      Issued To: {certificate.issuedTo?.name}
                    </Text>
                    <Text color={"fg.muted"} fontStyle={"italic"}>
                      Issued By: {certificate.issuedBy}
                    </Text>
                    <Text color={"fg.muted"} fontStyle={"italic"}>
                      Issued On:{" "}
                      {
                        new Date(certificate.validFrom)
                          .toISOString()
                          .split("T")[0]
                      }
                    </Text>
                    <Text color={"fg.muted"} fontStyle={"italic"}>
                      Expires On:{" "}
                      {certificate.validTo &&
                        new Date(certificate.validTo)
                          .toISOString()
                          .split("T")[0]}
                      {!certificate.validTo && "N/A"}
                    </Text>
                  </SimpleGrid>
                </Stack>
              );
            })}
            {certificates?.length === 0 && (
              <Text>
                You don't have any course certificates right now. Complete
                courses to obtain certificates or upload certificates you've
                earned from other organizations.
              </Text>
            )}
          </GridItem>
        </Grid>
      </Stack>
    </Box>
  );
};
