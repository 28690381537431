import { Box, Button, Card, CardBody, Center, FormControl, FormLabel, Heading, HStack, Icon, Image, Input, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, OrderedList, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { ProviderIntegrationSummary } from "../../types-new";

import { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { LuUnplug } from "react-icons/lu";
import { useCreateProviderSecret, useDeleteProviderSecret } from "../../api/endpoints";
import { DeleteButton } from "../../components/delete-button";
import { DataTable } from "../../components/table";


export const GenericTab: React.FC<{ description: string, demoProviders: ProviderIntegrationSummary[], header: string }> = ({ description, demoProviders, header }) => {
    const [selectedProviderDetails, setSelectedProviderDetails] = useState<ProviderIntegrationSummary>();
    const [connectionInput, setConnectionInput] = useState('');
    const { mutate: disconnectProvider } = useDeleteProviderSecret();
    const { mutate: connectProvider, isPending: connectionIsPending, isSuccess: connectionIsSuccessful } = useCreateProviderSecret();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const openModal = (provider: ProviderIntegrationSummary) => {
        setSelectedProviderDetails({ ...provider });
        onOpen();
    }

    const getProviderIcon = (provider: ProviderIntegrationSummary) => (
        provider.thumbnailImageName || provider.thumbnailImageUrl ?
            <Image
                maxWidth={20}
                src={provider.notImplemented ? provider.thumbnailImageUrl : `/assets/providers/${provider.thumbnailImageName}`}
                alt={`${provider.thumbnailImageName}`}
            />
            :
            <Center width={20} height={20}><Icon as={LuUnplug} boxSize={10} /></Center>
    );

    useEffect(() => {
        if (connectionIsSuccessful) {
            onClose();
        }
    }, [connectionIsSuccessful, onClose]);

    const getConnectCta = (provider: ProviderIntegrationSummary) => (
        provider.isConnected ?
            <DeleteButton
                aria-label="disconnect provider"
                color={"brand.primary.500"}
                deleteKey={provider.name}
                deleteButtonText={"Disconnect"}
                leftIcon={undefined}
                modalHeader={`Disconnect ${provider.name}`}
                modalText={`Are you sure you want to disconnect ${provider.name}? This action cannot be undone.`}
                name={"Disconnect"}
                onDelete={
                    () => disconnectProvider({ providerId: provider.id ?? "", secretId: provider.secretReferenceId ?? "" })
                }
                variant={"outline"}
            >
                Disconnect
            </DeleteButton>
            :
            <Button width={"100%"} variant="solid" colorScheme="brand.primary" onClick={() => openModal(provider)}>
                Connect
            </Button>
    );

    const columnHelper = createColumnHelper<ProviderIntegrationSummary>();
    const columns = [
        columnHelper.display({
            id: "icon",
            cell: ({ row: { original: provider } }) => getProviderIcon(provider),
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => `${row.name} ${row.description}`, {
            id: "name-desc",
            cell: ({ row: { original: provider } }) => (
                <Box>
                    <HStack gap={".25rem"}>
                        {provider.isConnected && <Icon as={HiCheckCircle} color="green.500" boxSize={5} />}
                        <Heading>{provider.name}</Heading>
                    </HStack>
                    <Text color={"brand.accent"}>{provider.description}</Text>
                </Box>
            ),
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => row.isConnected, {
            id: "connectCta",
            cell: ({ row: { original: provider } }) => getConnectCta(provider),
            footer: (props) => props.column.id,
        }),
    ];

    const getModalContent = () => (
        selectedProviderDetails &&
        (selectedProviderDetails.notImplemented ?
            <ModalContent>
                <ModalHeader>Whoops!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center p={10}>
                        <Text>
                            This feature is currently in private beta. Please contact
                            support@axlemobility.com for assistance.
                        </Text>
                    </Center>
                </ModalBody>
            </ModalContent>
            :
            <ModalContent>
                <ModalHeader>Connect {selectedProviderDetails.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack align={"flex-start"} gap={6}>
                        <VStack width={"100%"} align={"flex-start"}>
                            <Heading>
                                Copy & Paste API Token
                            </Heading>
                            <FormControl isRequired>
                                <FormLabel>API Token</FormLabel>
                                <Input
                                    placeholder='Token'
                                    onChange={(e: any) => setConnectionInput(e.target.value)}
                                />
                            </FormControl>
                        </VStack>
                        <Card variant={"outline"} width={"100%"} bg={"brand.gray.100"} borderColor={"brand.gray.300"}>
                            <CardBody>
                                <Heading mb={2}>
                                    {selectedProviderDetails.connectionInstructions?.header}
                                </Heading>
                                <OrderedList textColor={"brand.accent"}>
                                    {selectedProviderDetails.connectionInstructions?.body.split('\\n').map((line, index) => (
                                        <ListItem key={index}>{line}</ListItem>
                                    ))}
                                </OrderedList>
                            </CardBody>
                        </Card>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button color="brand.primary.500" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isDisabled={connectionInput?.length < 10}
                        isLoading={connectionIsPending}
                        variant={"solid"} colorScheme="brand.primary"
                        onClick={
                            () => connectProvider({
                                providerId: selectedProviderDetails.id,
                                providerName: selectedProviderDetails.name,
                                secretValue: connectionInput
                            })
                        }
                    >
                        Connect
                    </Button>
                </ModalFooter>
            </ModalContent>
        ));

    return (
        <Box>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
                <ModalOverlay />
                {getModalContent()}
            </Modal>
            <Heading fontSize={"lg"} mb={2}>
                {header}
            </Heading>
            <Text variant="md" color={"brand.accent"} fontWeight={400}>
                {description}
            </Text>
            <DataTable
                columns={columns}
                data={demoProviders ?? []}
                suppressHeaderRow={true}
                suppressPaginationFooter={true}
            />
        </Box>
    );
}