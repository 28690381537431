import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  useUpdateVehicle,
  useVehicle,
  useVehicleMakes,
  useVehicleModels,
} from "../../api/endpoints/vehicles";
import { Select, createFilter } from "chakra-react-select";
import { useEffect, useState } from "react";
import { Vehicle } from "../../types";
import { useSites } from "../../api/endpoints/sites";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Site } from "../../types-new/organizations";

ChartJS.register(ArcElement, ChartTooltip, Legend);

export const VehicleInformationTab = ({ vehicle }: { vehicle?: Vehicle }) => {
  const schema = Yup.object().shape({});
  const {
    data: sites,
    isError: isSitesError,
    isLoading: isSitesLoading,
  } = useSites();

  const siteOptions: readonly any[] | undefined = (sites ?? []).map(
    (x: Site) => {
      return {
        label: x.name,
        value: x.id,
      };
    }
  );

  const { data: updatedVehicle, mutate: updateVehicle } = useUpdateVehicle();

  const formik = useFormik({
    initialValues: {
      vin: vehicle?.vin,
      name: vehicle?.name,
      plate: vehicle?.plate,
      type: vehicle?.type,
      fuelType: vehicle?.fuelType,
      year: vehicle?.year,
      engineYear: vehicle?.engineYear,
      isUsedForDrayage: vehicle?.isUsedForDrayage,
      purchaseDate: vehicle?.purchaseDate,
      make: vehicle?.make,
      model: vehicle?.model,
      trim: vehicle?.trim,
      color: vehicle?.color,
      odometer: vehicle?.odometer,
      assignedToSiteId: vehicle?.assignedToSite.id,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      await updateVehicle({ id: vehicle?.id ?? "", body: values });
    },
  });

  const {
    data: makes,
    isError: isMakesError,
    isLoading: isMakesLoading,
  } = useVehicleMakes();

  const {
    data: models,
    isError: isModelsError,
    isLoading: isModelsLoading,
  } = useVehicleModels(formik?.values?.make ?? "");

  useEffect(() => {
    const makeOptions = makes?.map((make) => {
      return { label: make, value: make };
    });

    setMakeOptions(makeOptions ?? []);
  }, [makes]);

  useEffect(() => {
    const modelOptions = models?.map((model) => {
      return { label: model, value: model };
    });

    setModelOptions(modelOptions ?? []);
  }, [models, formik.values.make]);

  const [makeOptions, setMakeOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [modelOptions, setModelOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const vehicleTypeOptions = [
    { label: "Convertible/Cabriolet", value: "Convertible/Cabriolet" },
    { label: "Minivan", value: "Minivan" },
    { label: "Coupe", value: "Coupe" },
    {
      label: "Low Speed Vehicle (LSV) / Neighborhood Electric Vehicle (NEV)",
      value: "Low Speed Vehicle (LSV) / Neighborhood Electric Vehicle (NEV)",
    },
    {
      label: "Hatchback/Liftback/Notchback",
      value: "Hatchback/Liftback/Notchback",
    },
    { label: "Motorcycle - Standard", value: "Motorcycle - Standard" },
    {
      label: "Sport Utility Vehicle (SUV)/Multi-Purpose Vehicle (MPV)",
      value: "Sport Utility Vehicle (SUV)/Multi-Purpose Vehicle (MPV)",
    },
    {
      label: "Crossover Utility Vehicle (CUV)",
      value: "Crossover Utility Vehicle (CUV)",
    },
    { label: "Van", value: "Van" },
    { label: "Roadster", value: "Roadster" },
    { label: "Truck", value: "Truck" },
    { label: "Motorcycle - Scooter", value: "Motorcycle - Scooter" },
    { label: "Sedan/Saloon", value: "Sedan/Saloon" },
    { label: "Wagon", value: "Wagon" },
    { label: "Bus", value: "Bus" },
    { label: "Pickup", value: "Pickup" },
    { label: "Trailer", value: "Trailer" },
    { label: "Incomplete - Cutaway", value: "Incomplete - Cutaway" },
    {
      label: "Incomplete - Chassis Cab (Single Cab)",
      value: "Incomplete - Chassis Cab (Single Cab)",
    },
    { label: "Incomplete - Glider", value: "Incomplete - Glider" },
    { label: "Incomplete", value: "Incomplete" },
    { label: "Truck-Tractor", value: "Truck-Tractor" },
    {
      label: "Incomplete - Stripped Chassis",
      value: "Incomplete - Stripped Chassis",
    },
    { label: "Streetcar / Trolley", value: "Streetcar / Trolley" },
    {
      label: "Off-road Vehicle - All Terrain Vehicle (ATV) (Motorcycle-style)",
      value: "Off-road Vehicle - All Terrain Vehicle (ATV) (Motorcycle-style)",
    },
    {
      label: "Incomplete - Chassis Cab (Double Cab)",
      value: "Incomplete - Chassis Cab (Double Cab)",
    },
    {
      label: "Incomplete - School Bus Chassis",
      value: "Incomplete - School Bus Chassis",
    },
    {
      label: "Incomplete - Commercial Bus Chassis",
      value: "Incomplete - Commercial Bus Chassis",
    },
    { label: "Bus - School Bus", value: "Bus - School Bus" },
    {
      label: "Incomplete - Chassis Cab (Number of Cab Unknown)",
      value: "Incomplete - Chassis Cab (Number of Cab Unknown)",
    },
    {
      label: "Incomplete - Transit Bus Chassis",
      value: "Incomplete - Transit Bus Chassis",
    },
    {
      label: "Incomplete - Motor Coach Chassis",
      value: "Incomplete - Motor Coach Chassis",
    },
    {
      label: "Incomplete - Shuttle Bus Chassis",
      value: "Incomplete - Shuttle Bus Chassis",
    },
    {
      label: "Incomplete - Motor Home Chassis",
      value: "Incomplete - Motor Home Chassis",
    },
    { label: "Motorcycle - Sport", value: "Motorcycle - Sport" },
    {
      label: "Motorcycle - Touring / Sport Touring",
      value: "Motorcycle - Touring / Sport Touring",
    },
    { label: "Motorcycle - Cruiser", value: "Motorcycle - Cruiser" },
    {
      label: "Motorcycle - Three-Wheeled Motorcycle (2 Rear Wheels)",
      value: "Motorcycle - Three-Wheeled Motorcycle (2 Rear Wheels)",
    },
    {
      label: "Off-road Vehicle - Dirt Bike / Off-Road",
      value: "Off-road Vehicle - Dirt Bike / Off-Road",
    },
    {
      label: "Motorcycle - Dual Sport / Adventure / Supermoto / On/Off-road",
      value: "Motorcycle - Dual Sport / Adventure / Supermoto / On/Off-road",
    },
    {
      label: "Off-road Vehicle - Enduro (Off-road long distance racing)",
      value: "Off-road Vehicle - Enduro (Off-road long distance racing)",
    },
    {
      label: "Motorcycle - Small / Minibike",
      value: "Motorcycle - Small / Minibike",
    },
    {
      label: "Off-road Vehicle - Go Kart",
      value: "Off-road Vehicle - Go Kart",
    },
    { label: "Motorcycle - Side Car", value: "Motorcycle - Side Car" },
    { label: "Motorcycle - Custom", value: "Motorcycle - Custom" },
    { label: "Cargo Van", value: "Cargo Van" },
    {
      label: "Off-road Vehicle - Snowmobile",
      value: "Off-road Vehicle - Snowmobile",
    },
    { label: "Motorcycle - Street", value: "Motorcycle - Street" },
    {
      label:
        "Motorcycle - Enclosed Three Wheeled or Enclosed Autocycle [1 Rear Wheel]",
      value:
        "Motorcycle - Enclosed Three Wheeled or Enclosed Autocycle [1 Rear Wheel]",
    },
    {
      label:
        "Motorcycle - Unenclosed Three Wheeled or Open Autocycle [1 Rear Wheel]",
      value:
        "Motorcycle - Unenclosed Three Wheeled or Open Autocycle [1 Rear Wheel]",
    },
    { label: "Motorcycle - Moped", value: "Motorcycle - Moped" },
    {
      label:
        "Off-road Vehicle - Multipurpose Off-Highway Utility Vehicle [MOHUV] or Recreational Off-Highway Vehicle [ROV]",
      value:
        "Off-road Vehicle - Multipurpose Off-Highway Utility Vehicle [MOHUV] or Recreational Off-Highway Vehicle [ROV]",
    },
    { label: "Incomplete - Bus Chassis", value: "Incomplete - Bus Chassis" },
    { label: "Motorhome", value: "Motorhome" },
    {
      label: "Motorcycle - Cross Country",
      value: "Motorcycle - Cross Country",
    },
    { label: "Motorcycle - Underbone", value: "Motorcycle - Underbone" },
    { label: "Step Van / Walk-in Van", value: "Step Van / Walk-in Van" },
    {
      label: "Incomplete - Commercial Chassis",
      value: "Incomplete - Commercial Chassis",
    },
    {
      label:
        "Off-road Vehicle - Motocross (Off-road short distance, closed track racing)",
      value:
        "Off-road Vehicle - Motocross (Off-road short distance, closed track racing)",
    },
    { label: "Motorcycle - Competition", value: "Motorcycle - Competition" },
    {
      label: "Incomplete - Trailer Chassis",
      value: "Incomplete - Trailer Chassis",
    },
    { label: "Limousine", value: "Limousine" },
    { label: "Sport Utility Truck (SUT)", value: "Sport Utility Truck (SUT)" },
    {
      label: "Off-road Vehicle - Golf Cart",
      value: "Off-road Vehicle - Golf Cart",
    },
    {
      label: "Motorcycle - Unknown Body Class",
      value: "Motorcycle - Unknown Body Class",
    },
    {
      label: "Off-road Vehicle - Farm Equipment",
      value: "Off-road Vehicle - Farm Equipment",
    },
    {
      label: "Off-road Vehicle - Construction Equipment",
      value: "Off-road Vehicle - Construction Equipment",
    },
    { label: "Ambulance", value: "Ambulance" },
    { label: "Street Sweeper", value: "Street Sweeper" },
    { label: "Fire Apparatus", value: "Fire Apparatus" },
    {
      label:
        "Motorcycle - Three Wheeled, Unknown Enclosure or Autocycle, Unknown Enclosure",
      value:
        "Motorcycle - Three Wheeled, Unknown Enclosure or Autocycle, Unknown Enclosure",
    },
  ];

  const fuelTypeOptions = [
    { label: "Diesel", value: "Diesel" },
    { label: "Electric", value: "Electric" },
    { label: "Gasoline", value: "Gasoline" },
    {
      label: "Compressed Natural Gas (CNG)",
      value: "Compressed Natural Gas (CNG)",
    },
    {
      label: "Liquefied Natural Gas (LNG)",
      value: "Liquefied Natural Gas (LNG)",
    },
    {
      label: "Compressed Hydrogen/Hydrogen",
      value: "Compressed Hydrogen/Hydrogen",
    },
    {
      label: "Liquefied Petroleum Gas (propane or LPG)",
      value: "Liquefied Petroleum Gas (propane or LPG)",
    },
    { label: "Ethanol (E85)", value: "Ethanol (E85)" },
    { label: "Neat Ethanol (E100)", value: "Neat Ethanol (E100)" },
    { label: "Methanol (M85)", value: "Methanol (M85)" },
    { label: "Neat Methanol (M100)", value: "Neat Methanol (M100)" },
    {
      label: "Flexible Fuel Vehicle (FFV)",
      value: "Flexible Fuel Vehicle (FFV)",
    },
    { label: "Natural Gas", value: "Natural Gas" },
    { label: "Fuel Cell", value: "Fuel Cell" },
  ];

  const carColorOptions = [
    { label: "Black", value: "black" },
    { label: "White", value: "white" },
    { label: "Silver", value: "silver" },
    { label: "Gray", value: "gray" },
    { label: "Red", value: "red" },
    { label: "Blue", value: "blue" },
    { label: "Brown", value: "brown" },
    { label: "Green", value: "green" },
    { label: "Yellow", value: "yellow" },
    { label: "Multi", value: "multi" },
  ];

  return (
    <Box height={"100vh"}>
      <FormikProvider value={formik}>
        <Form>
          <FormControl
            isInvalid={
              !!formik?.errors?.name === true &&
              !!formik?.touched?.name === true
            }
            mb={5}
          >
            <FormLabel>Vehicle Name</FormLabel>
            <Field
              as={Input}
              id="name"
              name={"name"}
              type="name"
              placeholder="Example Name"
              value={formik?.values?.name}
            />
            <FormHelperText>The name of this vehicle.</FormHelperText>
            <FormErrorMessage>
              {formik?.errors?.name?.toString()}
            </FormErrorMessage>
          </FormControl>

          <SimpleGrid columns={[1, 2]} gap={"10px"}>
            <FormControl
              isInvalid={
                !!formik?.errors?.plate === true &&
                !!formik?.touched?.plate === true
              }
              mb={5}
            >
              <FormLabel>Plate</FormLabel>
              <Field
                as={Input}
                id="plate"
                name={"plate"}
                type="text"
                placeholder="License Plate"
                value={formik?.values?.plate}
              />

              <FormErrorMessage>
                {formik?.errors?.plate?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                //@ts-ignore
                !!formik?.errors?.assignedToSiteId === true &&
                !!formik?.touched?.assignedToSiteId === true
              }
              mb={5}
            >
              <FormLabel>Assigned Site</FormLabel>
              <Select
                options={siteOptions}
                placeholder={"Assigned Site"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("assignedToSiteId", e.value);
                }}
                id="assignedToSiteId"
                name={"assignedToSiteId"}
                value={siteOptions.find(
                  (x) => x.value === formik.values?.assignedToSiteId
                )}
              />

              <FormErrorMessage>
                {formik?.errors?.assignedToSiteId?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.type === true &&
                !!formik?.touched?.type === true
              }
              mb={5}
            >
              <FormLabel>Type</FormLabel>
              <Select
                options={vehicleTypeOptions}
                placeholder={"Vehicle Type"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("type", e.value);
                }}
                id="type"
                name={"type"}
                value={vehicleTypeOptions.find(
                  (x) => x.value === formik.values.type
                )}
              />
              <FormErrorMessage>
                {formik?.errors?.type?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.fuelType === true &&
                !!formik?.touched?.fuelType === true
              }
              mb={5}
            >
              <FormLabel>Primary Fuel Type</FormLabel>
              <Select
                options={fuelTypeOptions}
                placeholder={"Primary Vehicle Type"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("fuelType", e.value);
                }}
                id="fuelType"
                name={"fuelType"}
                value={fuelTypeOptions.find(
                  (x) => x.value === formik.values.fuelType
                )}
              />

              <FormErrorMessage>
                {formik?.errors?.fuelType?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.year === true &&
                !!formik?.touched?.year === true
              }
              mb={5}
            >
              <FormLabel>Year</FormLabel>
              <Field
                as={Input}
                id="year"
                name={"year"}
                type="number"
                placeholder="Year"
                value={formik?.values?.year}
              />
              <FormHelperText>e.g. 2000, 2024, etc.</FormHelperText>
              <FormErrorMessage>
                {formik?.errors?.year?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.engineYear === true &&
                !!formik?.touched?.engineYear === true
              }
              mb={5}
            >
              <FormLabel>Engine Year</FormLabel>
              <Field
                as={Input}
                id="engineYear"
                name={"engineYear"}
                type="number"
                placeholder="Engine Year"
                value={formik?.values?.engineYear}
              />
              <FormHelperText>e.g. 2000, 2024, etc.</FormHelperText>
              <FormErrorMessage>
                {formik?.errors?.engineYear?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.purchaseDate === true &&
                !!formik?.touched?.purchaseDate === true
              }
              mb={5}
            >
              <FormLabel>Purchase Date</FormLabel>
              <Field
                as={Input}
                id="purchaseDate"
                name={"purchaseDate"}
                type="date"
                placeholder={formik?.values?.purchaseDate}
                value={formik?.values?.purchaseDate?.split("T")[0]}
                onChange={(e: any) =>
                  formik.setFieldValue("purchaseDate", e.target.value)
                }
              />
              <FormHelperText>e.g. 1/1/2024, etc.</FormHelperText>
              <FormErrorMessage>
                {formik?.errors?.purchaseDate?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.make === true &&
                !!formik?.touched?.make === true
              }
              mb={5}
            >
              <FormLabel>Make</FormLabel>
              <Select
                options={makeOptions}
                placeholder={"Primary Vehicle Type"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("make", e.value);
                }}
                id="make"
                name={"make"}
                value={makeOptions?.find((x) => x.value === formik.values.make)}
                filterOption={createFilter({ ignoreAccents: false })}
              />
              <FormHelperText>e.g. Toyota, GMC, Chevrolet, etc.</FormHelperText>
              <FormErrorMessage>
                {formik?.errors?.make?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.model === true &&
                !!formik?.touched?.model === true
              }
              mb={5}
            >
              <FormLabel>Model</FormLabel>
              <Select
                options={modelOptions}
                placeholder={"Model"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("model", e.value);
                }}
                id="model"
                name={"model"}
                value={modelOptions?.find(
                  (x) => x.value === formik.values.model
                )}
                filterOption={createFilter({ ignoreAccents: false })}
              />

              <FormErrorMessage>
                {formik?.errors?.model?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.odometer === true &&
                !!formik?.touched?.odometer === true
              }
              mb={5}
            >
              <FormLabel>Odometer</FormLabel>
              <Field
                as={Input}
                id="odometer"
                name={"odometer"}
                type="number"
                placeholder="Odometer"
                value={formik?.values?.odometer}
              />
              <FormHelperText>
                The current mileage of the vehicle indicated by the odomoter.
              </FormHelperText>
              <FormErrorMessage>
                {formik?.errors?.odometer?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.trim === true &&
                !!formik?.touched?.trim === true
              }
              mb={5}
            >
              <FormLabel>Trim</FormLabel>
              <Field
                as={Input}
                id="trim"
                name={"trim"}
                type="text"
                placeholder="Trim"
                value={formik?.values?.trim}
              />

              <FormErrorMessage>
                {formik?.errors?.trim?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.color === true &&
                !!formik?.touched?.color === true
              }
              mb={5}
            >
              <FormLabel>Color</FormLabel>
              <Select
                options={carColorOptions}
                placeholder={"Primary Vehicle Color"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("color", e.value);
                }}
                id="color"
                name={"color"}
                value={carColorOptions?.find(
                  (x) => x.value === formik.values.color
                )}
              />
              <FormErrorMessage>
                {formik?.errors?.color?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.isUsedForDrayage === true &&
                !!formik?.touched?.isUsedForDrayage === true
              }
              mb={5}
            >
              <FormLabel>Vehicle Purpose</FormLabel>
              <Checkbox
                colorScheme="green"
                name="isUsedForDrayage"
                isChecked={formik.values.isUsedForDrayage}
                onChange={(e) => {
                  formik.setFieldValue("isUsedForDrayage", e.target.checked);
                }}
              >
                This vehicle is used for drayage.
              </Checkbox>
              <FormErrorMessage>
                <ErrorMessage name="isUsedForDrayage" />
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <Box justifyContent={"right"} display={"flex"}>
            <Button
              onClick={formik.submitForm}
              variant="solid"
              colorScheme="brand.primary"
            >
              Save
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
};
