import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  HStack,
  Heading,
  Text,
} from "@chakra-ui/react";
import { OrganizationDetailsSection } from "./organization-details-section";
import { OrganizationDangerZoneSection } from "./organization-dangerzone-section";
import { OrganizationComplianceSection } from "./organization-compliance-section";

export const OrganizationTab: React.FC = () => {
  return (
    <Box>
      <Heading as={"h3"} my={{ base: "4", md: "8" }}>
        General
      </Heading>
      <OrganizationDetailsSection />
      <Divider />
      <Heading as={"h3"} my={{ base: "4", md: "8" }}>
        Compliance
      </Heading>
      <OrganizationComplianceSection />

      <Divider />
      <Heading as={"h3"} my={{ base: "4", md: "8" }}>
        Danger Zone
      </Heading>
      <OrganizationDangerZoneSection />
    </Box>
  );
};
