import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useAuth } from "@clerk/clerk-react";
import { createColumnHelper } from "@tanstack/react-table";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ChevronRight, PlusIcon } from "lucide-react";
import React from "react";
import { LuUnplug } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useProviders } from "../../api/endpoints/providers";
import { useVehicles } from "../../api/endpoints/vehicles";
import { PageHeader } from "../../components/page-header";
import { DataTable } from "../../components/table";
import { Vehicle } from "../../types";
import { ProviderIntegrationSummary } from "../../types-new";

export const VehiclesListPage: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { enableConnections } = useFlags();
  const { orgRole } = useAuth();
  const isAdmin = orgRole && orgRole === "admin";

  const showProviderConnectionOptions = enableConnections && isAdmin;

  const { data: providerSummaries } = useProviders(
    showProviderConnectionOptions
  );

  const connectedProviders = (providerSummaries ?? []).filter(
    (p) => p.isConnected
  );

  const getProviderIcon = (provider: ProviderIntegrationSummary) =>
    provider.thumbnailImageName || provider.thumbnailImageUrl ? (
      <Image
        maxWidth={16}
        mr={4}
        src={
          provider.notImplemented
            ? provider.thumbnailImageUrl
            : `/assets/providers/${provider.thumbnailImageName}`
        }
        alt={`${provider.thumbnailImageName}`}
      />
    ) : (
      <Center width={20} height={20}>
        <Icon as={LuUnplug} boxSize={10} />
      </Center>
    );

  const getModal = () => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Telematics Integrations</ModalHeader>
        <ModalCloseButton />
        {connectedProviders?.length ? (
          <ModalBody>
            <VStack align={"flex-start"} gap={4}>
              <Box>
                <Text variant="lg">
                  You have {connectedProviders.length} connection(s)
                  established.
                </Text>
                <Text color={"brand.accent"} marginTop={2}>
                  Select provider to start syncing data from:
                </Text>
              </Box>
              <VStack w={"100%"}>
                {connectedProviders.map((p) => (
                  <Button
                    height={"100"}
                    fontSize={"md"}
                    justifyContent={"left"}
                    variant={"outline"}
                    width={"100%"}
                  >
                    {getProviderIcon(p)}
                    <Text>{p.name}</Text>
                  </Button>
                ))}
              </VStack>
            </VStack>
          </ModalBody>
        ) : (
          <ModalBody>
            <Text variant="lg">You have no connections established.</Text>
            <Button
              rightIcon={<ChevronRight />}
              colorScheme="brand.primary"
              variant="solid"
              marginTop={6}
              onClick={() => navigate("/connections/gps-telematics")}
              fontWeight={"bold"}
              fontSize={"medium"}
            >
              Establish Connection
            </Button>
          </ModalBody>
        )}
        <ModalFooter>
          <Button color="brand.primary.500" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const getMenuButton = () =>
    showProviderConnectionOptions ? (
      <Menu>
        <MenuButton
          as={Button}
          width={{ base: "100%", md: "auto" }}
          leftIcon={<PlusIcon size={".875rem"} />}
          variant="outline"
        >
          Add Vehicles
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => navigate("/vehicles/create")}>
            Add Multiple Vehicles
          </MenuItem>
          <MenuItem onClick={onOpen}>Sync from Telematics Integration</MenuItem>
          <MenuItem as="a" href="#" disabled>
            Import Vehicles
          </MenuItem>
          <MenuItem as="a" href="#" disabled>
            Export CSV
          </MenuItem>
        </MenuList>
      </Menu>
    ) : (
      <Button
        width={{ base: "100%", md: "auto" }}
        leftIcon={<PlusIcon size={".875rem"} />}
        variant="outline"
        onClick={() => {
          navigate("/vehicles/create");
        }}
      >
        Add Vehicle
      </Button>
    );

  const columnHelper = createColumnHelper<Vehicle>();
  const navigate = useNavigate();

  const { data: vehicles } = useVehicles();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue() ?? "N/A",
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.make, {
      id: "make",
      header: () => <span>Make</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.model, {
      id: "model",
      header: () => <span>Model</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.vin, {
      id: "vin",
      header: () => <span>VIN</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.year, {
      id: "year",
      header: () => <span>Year</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.purchaseDate, {
      id: "yearsOfService",
      header: () => <span>Years of Service</span>,
      cell: (info) => {
        const purchaseDate = new Date(info.getValue());
        const currentDate = new Date();
        const yearsOfService =
          currentDate.getFullYear() - purchaseDate.getFullYear();
        return yearsOfService.toString();
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.color, {
      id: "color",
      header: () => <span>Color</span>,
      cell: (info) =>
        info.getValue()
          ? info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)
          : "N/A",
      footer: (props) => props.column.id,
    }),
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Vehicles"></PageHeader>
      <Stack p={5}>
        {getModal()}
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={vehicles ?? []}
              actionChildren={getMenuButton()}
              onRowClick={(row) => {
                navigate(`/vehicles/${(vehicles ?? [])[row].id}`);
              }}
            ></DataTable>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
