import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  HStack,
  Heading,
  IconButton,
  Text,
  VStack,
  Input,
  Skeleton,
} from "@chakra-ui/react";

import { PageHeader } from "../../components/page-header";
import { ArrowLeftIcon, DownloadIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  useCreateVMRSDecodeOrder,
  useVMRSDecodeOrder,
} from "../../api/endpoints/vmrs";
import "./index.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSVImporter } from "csv-import-react";
import { ReactComponent as VMRSPartsPostTableIcon } from "../../icons/vmrs-parts-post-table.svg";
import { ReactComponent as VMRSPartsPreTableIcon } from "../../icons/vmrs-parts-pre-table.svg";
import { ReactComponent as VMRSRepairNotesPreTableIcon } from "../../icons/vmrs-repair-post-table.svg";
import { ReactComponent as VMRSRepairNotesPostTableIcon } from "../../icons/vmrs-repair-pre-table.svg";
import { ReactComponent as LeftIcon } from "../../icons/left-icon.svg";
import { DataTable } from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import CsvDownloader from "react-csv-downloader";

type PartIdentificationRecommendation = {
  system: string;
  assembly: string;
  component: string;
  code: string;
};

type VMRSCodeRecommendation = {
  parts: PartIdentificationRecommendation[];
  customerNotes: string;
};

type VMRSPartConversionResult = {
  partName: string;
  externalId: string;
  vmrsPartName: string;
  vmrsPartId: string;
  isFetching: boolean;
};

export const VMRSIndexPage: React.FC = () => {
  const {
    mutate: createVMRSDecodeOrder,
    isPending: isCreatingVMRSDecodeOrder,
    isSuccess: isCreateVMRSDecodeOrderSuccess,
    data: decodeOrderId,
  } = useCreateVMRSDecodeOrder();

  const {
    data: vmrsDecodeOrder,
    isPending: isFetchingVMRSDecodeOrder,
    isSuccess: isFetchVMRSDecodeOrderSuccess,
    refetch: refetchVMRSDecodeOrder,
  } = useVMRSDecodeOrder(decodeOrderId ?? "", isCreateVMRSDecodeOrderSuccess);

  const container = useRef<HTMLDivElement>(null);

  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<VMRSCodeRecommendation | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [processingMode, setProcessingMode] = useState<
    "repair-notes" | "parts"
  >("parts");

  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [conversionResults, setConversionResults] = useState<
    VMRSPartConversionResult[]
  >([]);

  const handleInputChange = (index: number, field: string, value: string) => {
    setConversionResults((prevResults) => {
      const newResults = [...prevResults];
      newResults[index] = {
        ...newResults[index],
        [field]: value,
      };
      return newResults;
    });
  };

  const columnHelper = createColumnHelper<VMRSPartConversionResult>();

  const columns = [
    columnHelper.accessor((row) => row.partName, {
      id: "partName",
      header: () => <span>Part Name</span>,
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.externalId, {
      id: "externalId",
      header: () => <span>Part ID</span>,
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.vmrsPartName, {
      id: "vmrsPartName",
      header: () => <span>VMRS Part Name</span>,
      cell: (info) => (
        <Skeleton
          startColor="gray.100"
          endColor="gray.300"
          isLoaded={!info.row.original.isFetching}
          fadeDuration={4}
        >
          <Input
            value={info.getValue() ?? "N/A"}
            onChange={(e) =>
              handleInputChange(info.row.index, "vmrsPartName", e.target.value)
            }
          />
        </Skeleton>
      ),
    }),
    columnHelper.accessor((row) => row.vmrsPartId, {
      id: "vmrsPartId",
      header: () => <span>VMRS Code</span>,
      cell: (info) => (
        <Skeleton
          startColor="gray.100"
          endColor="gray.300"
          isLoaded={!info.row.original.isFetching}
          fadeDuration={4}
        >
          <Input
            value={info.getValue() ?? "N/A"}
            onChange={(e) =>
              handleInputChange(info.row.index, "vmrsPartId", e.target.value)
            }
          />
        </Skeleton>
      ),
    }),
  ];

  useEffect(() => {
    if (vmrsDecodeOrder) {
      setConversionResults(
        vmrsDecodeOrder.orderLines.map((orderLine) => {
          return {
            partName: orderLine.description,
            externalId: orderLine.externalId,
            vmrsPartName: orderLine?.parts?.[0]?.component ?? "N/A",
            vmrsPartId: orderLine?.parts?.[0]?.code ?? "N/A",
            isFetching: orderLine.status.toLowerCase() === "waiting",
          };
        })
      );
    }
  }, [vmrsDecodeOrder]);

  useEffect(() => {
    if (isCreateVMRSDecodeOrderSuccess) {
      navigate(`/vmrs/${decodeOrderId}`);
    }
  }, [isCreateVMRSDecodeOrderSuccess, decodeOrderId, navigate]);

  const createDecodeOrder = async (data: any) => {
    const values = data.rows.map((row: any) => {
      return {
        description: row.values.description,
        externalId: row.values.id,
      };
    });
    await createVMRSDecodeOrder({
      values,
      requestType: processingMode === "repair-notes" ? "RepairNotes" : "Parts",
    });
  };

  const csvImporter = useMemo(() => {
    return (
      <Box>
        <CSVImporter
          isModal={false}
          onComplete={(data) => createDecodeOrder(data)}
          template={{
            columns:
              processingMode === "repair-notes"
                ? [
                    {
                      name: "Repair Notes",
                      key: "description",
                      required: true,
                      description:
                        "The repair notes or part names to convert to VMRS Code(s).",
                      suggested_mappings: [
                        "Part",
                        "Repair",
                        "Notes",
                        "Description",
                        "Repair Note",
                      ],
                    },
                    {
                      name: "Task ID",
                      key: "id",
                      required: true,
                      description:
                        "The task ID you use to reference this RO note.",
                      suggested_mappings: ["Id"],
                    },
                  ]
                : [
                    {
                      name: "Part Name",
                      key: "description",
                      required: true,
                      description:
                        "The repair notes or part names to convert to VMRS Code(s).",
                      suggested_mappings: [
                        "Part",
                        "Name",
                        "Part Name",
                        "Description",
                      ],
                    },
                    {
                      name: "Part ID",
                      key: "id",
                      required: true,
                      description:
                        "The part ID you use to reference this part.",
                      suggested_mappings: ["Id"],
                    },
                  ],
          }}
          primaryColor="#204B36"
        />
      </Box>
    );
  }, [processingMode]);

  return (
    <Box height={"110vh"} overflow={"scroll"}>
      <PageHeader title="VMRS AI Assistant"></PageHeader>
      <Card variant={"outline"} m={5} height={"100%"}>
        <CardBody>
          {currentStep === 0 && (
            <Box>
              <Heading>VMRS AI Converter</Heading>
              <Text variant={"sm"}>
                {processingMode === "repair-notes"
                  ? "Upload your CSV file with repair notes to extract part information and capture associated VMRS codes."
                  : "Upload your CSV file with part names and IDs to convert to VMRS codes."}
              </Text>

              <VStack mt={"5%"} gap={10}>
                <HStack>
                  <Button
                    size={"xl"}
                    variant={
                      processingMode === "repair-notes" ? "inverted" : "outline"
                    }
                    onClick={() => setProcessingMode("repair-notes")}
                  >
                    Repair Notes Mode
                  </Button>
                  <Button
                    size={"xl"}
                    variant={
                      processingMode === "parts" ? "inverted" : "outline"
                    }
                    onClick={() => setProcessingMode("parts")}
                  >
                    Parts Mode
                  </Button>
                </HStack>
                <HStack>
                  {processingMode === "repair-notes" && (
                    <VMRSRepairNotesPreTableIcon />
                  )}
                  {processingMode === "parts" && <VMRSPartsPreTableIcon />}
                  <LeftIcon />
                  {processingMode === "repair-notes" && (
                    <VMRSRepairNotesPostTableIcon />
                  )}
                  {processingMode === "parts" && <VMRSPartsPostTableIcon />}
                </HStack>
                <Button
                  variant={"inverted"}
                  size={"lg"}
                  onClick={() => setCurrentStep(1)}
                >
                  Get Started
                </Button>
              </VStack>
            </Box>
          )}
          {currentStep === 1 && (
            <Box>
              <HStack>
                <IconButton
                  icon={<ArrowLeftIcon />}
                  onClick={() => {
                    setCurrentStep(0);
                  }}
                  aria-label={"Change Mode"}
                />
                <Heading size={"md"}>Change Mode</Heading>
              </HStack>
              {csvImporter}
            </Box>
          )}
          {currentStep === 2 && (
            <Box>
              <HStack>
                <IconButton
                  icon={<ArrowLeftIcon />}
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                  aria-label={"Back"}
                />
                <Heading size={"md"}>Converter</Heading>
              </HStack>
              <Divider color={"gray.400"} mb={10} />
              <Text color={"fg.muted"} size={"10px"} fontWeight={"normal"}>
                Axle converted the columns you selected to VMRS codes. This
                system is still learning and data may not always be accurate.
                Please double check the data before downloading.
              </Text>
              <DataTable
                columns={columns}
                data={conversionResults}
                isSearchEnabled={false}
                actionChildren={
                  <CsvDownloader
                    //@ts-ignore
                    datas={conversionResults}
                    filename={"conversion_results.csv"}
                  >
                    <Button
                      width={{ base: "100%", md: "auto" }}
                      leftIcon={<DownloadIcon size={".875rem"} />}
                      variant="outline"
                    >
                      Download CSV
                    </Button>
                  </CsvDownloader>
                }
              ></DataTable>
            </Box>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};
