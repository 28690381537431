import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export const OrganizationDangerZoneSection: React.FC = () => {
  return (
    <>
      <Card
        direction={{ base: "row", sm: "row" }}
        variant="outline"
        my={{ base: "4", md: "8" }}
      >
        <HStack width={"100%"}>
          <CardBody>
            <Heading size="md">Leave Organization</Heading>
            <Text py="2">
              Once you leave an organization, you must be re-invited by an admin
              to join it again.
            </Text>
          </CardBody>

          <CardFooter justifyContent={"right"}>
            <Button variant="outline" colorScheme="red">
              Leave Organization
            </Button>
          </CardFooter>
        </HStack>
      </Card>
      <Card direction={{ base: "row", sm: "row" }} variant="outline">
        <HStack width={"100%"}>
          <CardBody>
            <Heading size="md">Delete Organization</Heading>
            <Text py="2">
              Once you delete an organization, there is no going back. Please be
              certain.
            </Text>
          </CardBody>
          <CardFooter justifyContent={"right"}>
            <Button variant="solid" colorScheme="red">
              Delete Organization
            </Button>
          </CardFooter>
        </HStack>
      </Card>
    </>
  );
};
