import {
  Avatar,
  Badge,
  Box,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Button, HStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../components/table";
import { DeleteButton } from "../../../components/delete-button";
import { Form, Field, ErrorMessage, Formik } from "formik";
import { useAuth, useOrganization } from "@clerk/clerk-react";
import type { OrganizationMembershipResource } from "@clerk/types";
import { toast } from "react-toastify";
import { Select } from "chakra-react-select";
import * as Yup from "yup";
import { MailIcon, PlusIcon, RotateCw } from "lucide-react";
import { Member } from "../../../types-new";
import { useTeams } from "../../../api/endpoints/teams";
import {
  useAddUserToOrganization,
  useSendMemberAccountInformation,
} from "../../../api/endpoints/organizations";

export const MembersListPage: React.FC = () => {
  const { userId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { memberships, organization, isLoaded } = useOrganization({
    memberships: { infinite: true, pageSize: 500 },
  });

  const { data: teams } = useTeams();

  const { mutate: addUser } = useAddUserToOrganization();
  const { mutate: sendAccountInformation } = useSendMemberAccountInformation();

  useEffect(() => {
    while (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isLoading && !memberships?.isFetching) {
      const membershipSnapshot =
        memberships?.data?.map((membership) => {
          return {
            firstName: membership.publicUserData.firstName,
            lastName: membership.publicUserData.lastName,
            emailAddress: membership.publicUserData.identifier,
            id: membership.id,
            userId: membership.publicUserData.userId,
            profileImageUrl: membership.publicUserData.imageUrl,
            role: membership.role,
          } as Member;
        }) ?? [];

      setMembers(membershipSnapshot);
    }
  }, [isLoading, memberships?.isFetching]);

  const [members, setMembers] = useState<Member[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columnHelper = createColumnHelper<Member>();
  const initialValues = {
    email: "",
    role: "",
    defaultTeamAssignments: [],
    firstName: "",
    lastName: "",
  };

  const onMemberDelete = async (id: string) => {
    const result = await organization?.removeMember(id);
    toast.success(`Member successfully deleted.`);
  };

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().email(
      ({ value }) => `${value} is not a valid email. `
    ),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    defaultTeamAssignments: Yup.array(),
    role: Yup.string().oneOf(["admin", "basic_member"]),
  });

  useEffect(() => {
    const cols = [
      columnHelper.display({
        id: "Name",
        header: () => <span>Name</span>,
        cell: (props) => {
          const name =
            props.row.original.firstName + " " + props.row.original.lastName;

          return (
            <HStack spacing="3">
              <Avatar
                name={name}
                src={props.row.original.profileImageUrl}
                boxSize="10"
              />
              {(() => {
                if (props.row.original.status === "pending") {
                  return (
                    <Box>
                      <Text
                        fontWeight="medium"
                        fontStyle={"italic"}
                        color={"black"}
                      >
                        Pending Member
                      </Text>
                    </Box>
                  );
                } else {
                  return (
                    <Box>
                      <Text fontWeight="medium">{name}</Text>
                    </Box>
                  );
                }
              })()}
            </HStack>
          );
        },
      }),
      //There is a ticket open with Clerk to figure out why "has_logged_in" is not being returned in the user's public metadata.
      // columnHelper.accessor((row) => row.status, {
      //   id: "status",
      //   cell: (info) => {
      //     const value = info.getValue();
      //     return (
      //       <Badge variant={value === "active" ? "green" : "red"}>
      //         {value.charAt(0).toUpperCase() + value.slice(1)}
      //       </Badge>
      //     );
      //   },
      //   header: () => <span>Status</span>,
      //   footer: (props) => props.column.id,
      // }),
      columnHelper.accessor((row) => row.emailAddress, {
        id: "email",
        cell: (info) => info.getValue(),
        header: () => <span>Email</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor((row) => row.role, {
        id: "role",
        cell: (info) => info.getValue(),
        header: () => <span>Role</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "actions",
        header: () => <span>Actions</span>,
        cell: (props) => {
          return (
            <HStack gap={0}>
              {/* We can't delete ourselves and we can only delete if we are an admin. */}
              {props.row.original.userId !== userId && (
                <DeleteButton
                  deleteKey={props.row.original.emailAddress}
                  name={props.row.original.emailAddress}
                  aria-label="delete user"
                  onDelete={() => onMemberDelete(props.row.original.userId)}
                />
              )}
              <Tooltip label="Reset user's password and send them a reminder email.">
                <IconButton
                  icon={<RotateCw />}
                  color={"brand.accent"}
                  onClick={() =>
                    sendAccountInformation(props.row.original.userId)
                  }
                  aria-label="send account information"
                ></IconButton>
              </Tooltip>
            </HStack>
          );
        },
      }),
    ];
    setColumns(cols);
  }, []);

  const onSubmit = async (values: any) => {
    await addUser({
      emailAddress: values.email,
      role: values.role,
      defaultTeamAssignments: values.defaultTeamAssignments,
      firstName: values.firstName,
      lastName: values.lastName,
    });

    await organization?.reload();

    onClose();
  };

  const permissionsSelectOptions = [
    { label: "Member", value: "basic_member" },
    { label: "Admin", value: "admin" },
  ];

  const teamSelectOptions = teams?.map((team) => ({
    label: team.name,
    value: team.id,
  }));

  return (
    <Box height={"100vh"} overflow={"auto"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"2xl"}>
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalCloseButton />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleChange,
              touched,
              isValid,
              setFieldValue,
            }) => (
              <Form className="p-4 text-center">
                <ModalHeader>
                  <Heading variant={"md"}>Invite Member</Heading>
                </ModalHeader>
                <ModalBody color={"brand.text"}>
                  <HStack>
                    <FormControl
                      id="firstName"
                      isInvalid={!!errors.firstName && !!touched.firstName}
                    >
                      <Stack
                        direction={{ base: "column", md: "column" }}
                        justify="space-between"
                      >
                        <Box>
                          <FormLabel variant="inline">First Name</FormLabel>
                        </Box>
                        <Field
                          as={Input}
                          id="firstName"
                          name="firstName"
                          borderColor={"gray.400"}
                          type="text"
                          value={values.firstName}
                          onChange={handleChange}
                        />
                      </Stack>
                      <FormErrorMessage>
                        <ErrorMessage name="firstName" />
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="lastName"
                      isInvalid={!!errors.lastName && !!touched.lastName}
                    >
                      <Stack
                        direction={{ base: "column", md: "column" }}
                        justify="space-between"
                      >
                        <Box>
                          <FormLabel variant="inline">Last Name</FormLabel>
                        </Box>
                        <Field
                          as={Input}
                          borderColor={"gray.400"}
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={values.lastName}
                          onChange={handleChange}
                        />
                      </Stack>
                      <FormErrorMessage>
                        <ErrorMessage name="lastName" />
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                  <FormControl
                    id="email"
                    isInvalid={!!errors.email && !!touched.email}
                  >
                    <Stack
                      direction={{ base: "column", md: "column" }}
                      justify="space-between"
                    >
                      <Box>
                        <FormLabel variant="inline">Email Address</FormLabel>
                      </Box>
                      <Field
                        borderColor={"gray.400"}
                        as={Input}
                        id="email"
                        name="email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Stack>
                    <FormErrorMessage>
                      <ErrorMessage name="email" />
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="role"
                    mt={10}
                    isInvalid={!!errors.role && !!touched.role}
                  >
                    <Stack
                      direction={{ base: "column", md: "column" }}
                      justify="space-between"
                    >
                      <Box>
                        <FormLabel variant="inline">Role</FormLabel>
                        <FormHelperText mt="0" color="fg.muted">
                          Admins may invite new members or remove them and
                          change organization settings.
                        </FormHelperText>
                      </Box>
                      <Select
                        options={permissionsSelectOptions}
                        id="role"
                        placeholder={"Select Role"}
                        name="role"
                        closeMenuOnSelect={true}
                        value={permissionsSelectOptions?.find(
                          (template: any) => template.value === values.role
                        )}
                        onChange={(e: any) => {
                          setFieldValue("role", e.value);
                        }}
                      />
                      <FormErrorMessage>
                        <ErrorMessage name="role" />
                      </FormErrorMessage>
                    </Stack>
                  </FormControl>
                  <FormControl
                    id="defaultTeamAssignments"
                    mt={10}
                    isInvalid={
                      !!errors.defaultTeamAssignments &&
                      !!touched.defaultTeamAssignments
                    }
                  >
                    <Stack
                      direction={{ base: "column", md: "column" }}
                      justify="space-between"
                    >
                      <Box>
                        <FormLabel variant="inline">
                          Default Team Assignments
                        </FormLabel>
                        <FormHelperText mt="0" color="fg.muted">
                          Members will be assigned to these teams by default
                          (optional).
                        </FormHelperText>
                      </Box>
                      <Select
                        isMulti
                        options={teamSelectOptions}
                        id="role"
                        placeholder={"Select Teams"}
                        name="defaultTeamAssignments"
                        closeMenuOnSelect={true}
                        onChange={(e: any) => {
                          setFieldValue(
                            "defaultTeamAssignments",
                            e.map((opt: any) => opt.value)
                          );
                        }}
                      />
                      <FormErrorMessage>
                        <ErrorMessage name="role" />
                      </FormErrorMessage>
                    </Stack>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <HStack>
                    <Button colorScheme="red" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      bg={"brand.primary.500"}
                      _hover={{ backgroundColor: "brand.primary.500" }}
                      color={"brand.textLight"}
                      type="submit"
                      isDisabled={!isValid}
                    >
                      Send invite(s)
                    </Button>
                  </HStack>
                </ModalFooter>
              </Form>
            )}
          </Formik>

          {/* </form> */}
        </ModalContent>
      </Modal>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={members ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onOpen}
                >
                  Invite
                </Button>
              }
              title="Members"
              subtitle="Members are people in your organization."
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
