import {
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";

interface DeleteButtonProps {
  name?: string;
  deleteKey?: string;
  deleteButtonText?: string;
  modalHeader?: string;
  modalText?: string;
  onDelete?: () => void;
}

export const DeleteButton: React.FC<DeleteButtonProps & ButtonProps> = (
  props: DeleteButtonProps & ButtonProps
) => {
  const {
    deleteKey,
    deleteButtonText,
    modalHeader,
    modalText,
    name,
    onDelete,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmationKey, setConfirmationKey] = useState("");

  return (
    <>
      <Button
        variant={"inverted"}
        aria-label={props["aria-label"] ?? ""}
        leftIcon={<FiTrash2 />}
        onClick={onOpen}
        {...props}
      >
        {deleteButtonText}{" "}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader ?? "Delete Confirmation"}</ModalHeader>
          <ModalCloseButton />
          {deleteKey && (
            <ModalBody>
              <Text>
                {modalText ??
                  `Are you sure you want to delete ${name}? This action cannot be undone.`}
              </Text>
              <FormControl mt={5}>
                <FormLabel>
                  <chakra.p>
                    Confirm by typing{" "}
                    <chakra.pre display="inline" color="brand.accent">
                      {deleteKey}
                    </chakra.pre>{" "}
                    below.
                  </chakra.p>
                </FormLabel>
                <Input onChange={(e) => setConfirmationKey(e.target.value)} />
              </FormControl>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="red"
              mr={3}
              isDisabled={confirmationKey !== deleteKey}
              onClick={onDelete}
            >
              {deleteButtonText ?? "Delete"}
            </Button>
            <Button variant={"outline"} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
