import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Divider,
  HStack,
  Square,
  Icon,
  Button,
  ModalFooter,
  Center,
  CenterProps,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import { FormikProvider, Form, Field, FormikContextType } from "formik";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FiFileText, FiUploadCloud } from "react-icons/fi";
import { Evidence } from "../../../types";
import { useApi } from "../../../hooks/use-api";
import { toast } from "react-toastify";
import { useDetachFileFromEvidence } from "../../../api/endpoints/evidence";

interface DropZoneProps {
  onFileUpload: (files: File[]) => void;
}

export const Dropzone = (props: CenterProps & DropZoneProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    props.onFileUpload(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Center
      borderWidth="1px"
      borderRadius="lg"
      px="6"
      py="4"
      {...props}
      {...getRootProps()}
    >
      <VStack spacing="3">
        <Square size="10" bg="bg.subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            {isDragActive ? (
              <Text>Drop the files here ...</Text>
            ) : (
              <>
                <Button variant="text" colorScheme="blue" size="sm">
                  Click to upload
                </Button>
                <Text textStyle="sm" color="fg.muted">
                  or drag and drop
                </Text>
              </>
            )}
            <input {...getInputProps()} />
          </HStack>
          <Text textStyle="xs" color="fg.muted">
            PNG, JPG or GIF up to 2MB
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
};

export const EvidenceForm: React.FC<{
  formik: FormikContextType<any>;
  evidence?: Evidence | undefined;
}> = ({ formik, evidence }) => {
  const { mutate: detachFileFromEvidence, isError: detachFileError } =
    useDetachFileFromEvidence();

  const onDrop = (acceptedFiles: File[]) => {
    formik.setFieldValue("pendingFiles", acceptedFiles);
  };

  const removePendingFile = (fileIndex: number) => {
    const result = formik.values.files.filter(
      (file: File, index: number) => index !== fileIndex
    );
    formik.setFieldValue("pendingFiles", result);
  };

  const removeExistingFile = async (fileId: string) => {
    await detachFileFromEvidence({
      evidenceId: evidence?.id ?? "",
      fileId: fileId,
    });

    if (!detachFileError) {
      toast(`Successfully deleted file from evidence.`);
      formik.setFieldValue(
        "files",
        evidence?.files.filter((file) => file.id !== fileId)
      );
    } else {
      toast.error("Error deleting file from evidence.");
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack spacing="2">
          <Stack spacing="2" direction={{ base: "column", md: "row" }}>
            <FormControl
              isInvalid={
                !!formik?.errors?.name === true &&
                !!formik?.touched?.name === true
              }
            >
              <FormLabel>Name</FormLabel>
              <Field
                as={Input}
                id="name"
                name={"name"}
                type="text"
                placeholder="Example Name"
                value={formik.values.name}
              />
              <FormErrorMessage>
                {formik?.errors?.name?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.description === true &&
                !!formik?.touched?.description === true
              }
            >
              <FormLabel>Description</FormLabel>
              <Field
                as={Input}
                id="description"
                name={"description"}
                type="text"
                placeholder="Example Description"
                value={formik.values.description}
              />
              <FormErrorMessage>
                {formik?.errors?.description?.toString()}
              </FormErrorMessage>
            </FormControl>
          </Stack>

          <FormControl
            isInvalid={
              !!formik?.errors?.collectedOn === true &&
              !!formik?.touched?.collectedOn === true
            }
          >
            <FormLabel>Collected On</FormLabel>
            <Field
              as={Input}
              id="collectedOn"
              name={"collectedOn"}
              type="date"
              placeholder="mm/dd/yyyy"
              value={formik.values.collectedOn}
            />
            <FormErrorMessage>
              {formik?.errors?.collectedOn?.toString()}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!formik?.errors?.expiresOn === true &&
              !!formik?.touched?.expiresOn === true
            }
          >
            <FormLabel>Expires On</FormLabel>
            <Field
              as={Input}
              id="expiresOn"
              name={"expiresOn"}
              type="date"
              placeholder="mm/dd/yyyy"
              value={formik.values.expiresOn}
            />
            <FormErrorMessage>
              {formik?.errors?.expiresOn?.toString()}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="content"
            isInvalid={
              !!formik?.errors?.content === true &&
              !!formik?.touched?.content === true
            }
          >
            <FormLabel>Content</FormLabel>
            <Field
              as={Textarea}
              name={"content"}
              type="text"
              placeholder="Add raw evidence directly here or attach links. We
            recommend storing data in Google Drive, GCS/AWS
            buckets (or similar) and attaching the link here."
              value={formik.values.content}
              rows={3}
              resize="none"
            />
            <FormErrorMessage>
              {formik?.errors?.content?.toString()}
            </FormErrorMessage>
          </FormControl>
          {formik.values.pendingFiles?.length > 0 && (
            <>
              <Divider />
              <Stack spacing="5">
                <Stack spacing="1">
                  <Text textStyle="lg" fontWeight="medium">
                    Pending Files
                  </Text>
                  <Text textStyle="sm" color="fg.muted">
                    The files below were just added and are pending upload.
                    Files must be smaller than 10MB.
                  </Text>
                </Stack>
                {formik.values.pendingFiles?.map(
                  (file: File, index: number) => {
                    return (
                      <Box
                        borderWidth={{ base: "0", md: "1px" }}
                        p={{ base: "0", md: "4" }}
                        borderRadius="lg"
                      >
                        <Stack
                          justify="space-between"
                          direction={{ base: "column", md: "row" }}
                          spacing="5"
                        >
                          <HStack spacing="3">
                            <Square size="10" bg="bg.subtle" borderRadius="lg">
                              <Icon as={FiFileText} boxSize="5" />
                            </Square>
                            <Box fontSize="sm">
                              <Text color="empahsized" fontWeight="medium">
                                {file.name}
                              </Text>
                            </Box>
                          </HStack>
                          <Stack
                            spacing="3"
                            direction={{
                              base: "column-reverse",
                              md: "row",
                            }}
                          >
                            <Button
                              colorScheme="red"
                              onClick={() => removePendingFile(index)}
                            >
                              Remove
                            </Button>
                          </Stack>
                        </Stack>
                      </Box>
                    );
                  }
                )}
              </Stack>
            </>
          )}
          <FormControl id="files">
            <FormLabel>Files</FormLabel>
            <Dropzone width="full" onFileUpload={onDrop} />
          </FormControl>
          {evidence && evidence.files && evidence.files.length > 0 && (
            <>
              <Divider />
              <Stack spacing="5">
                <Stack spacing="1">
                  <Text textStyle="lg" fontWeight="medium">
                    Existing Files
                  </Text>
                  <Text textStyle="sm" color="fg.muted">
                    The files below were uploaded previously.
                  </Text>
                </Stack>
                {evidence.files?.map((x: any, index: number) => {
                  return (
                    <Box
                      borderWidth={{ base: "0", md: "1px" }}
                      p={{ base: "0", md: "4" }}
                      borderRadius="lg"
                    >
                      <Stack
                        justify="space-between"
                        direction={{ base: "column", md: "row" }}
                        spacing="5"
                      >
                        <HStack spacing="3">
                          <Square size="10" bg="bg.subtle" borderRadius="lg">
                            <Icon as={FiFileText} boxSize="5" />
                          </Square>
                          <Box fontSize="sm">
                            <Text color="empahsized" fontWeight="medium">
                              {x.name}
                            </Text>
                          </Box>
                        </HStack>
                        <Stack
                          spacing="3"
                          direction={{
                            base: "column-reverse",
                            md: "row",
                          }}
                        >
                          <Button
                            variant="secondary"
                            onClick={() => openInNewTab(x.url)}
                          >
                            Download
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={() => removeExistingFile(x.id)}
                          >
                            Remove
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>
            </>
          )}
        </Stack>
        <HStack justifyContent={"right"} mt={3}>
          <Button variant="solid" colorScheme="brand.primary" type="submit">
            Save
          </Button>
        </HStack>
      </Form>
    </FormikProvider>
  );
};
