import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from "@ajna/pagination";
import {
  Box,
  Card,
  CardBody,
  chakra,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  StatDownArrow,
  StatUpArrow,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  InitialTableState,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import "./table.css";

export type DataTableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData, any>[];
  initialState?: InitialTableState;
  getSubRowsFn?:
    | ((originalRow: TData, index: number) => TData[] | undefined)
    | undefined;
  onRowClick?: (rowId: number, columnId: number) => void;

  onTitleEdit?: (title: string) => void;
  onBackClick?: () => void;
  title?: string;
  subtitle?: string;
  actionChildren?: React.ReactNode;
  isSearchEnabled?: boolean;
  suppressHeaderRow?: boolean;
  suppressPaginationFooter?: boolean;
};

export function DataTable<TData>({
  data,
  columns,
  initialState = {},
  getSubRowsFn,
  onRowClick,
  onBackClick,
  onTitleEdit,
  title,
  subtitle,
  actionChildren,
  isSearchEnabled = true,
  suppressHeaderRow = false,
  suppressPaginationFooter = false,
}: DataTableProps<TData>) {
  const { pageSizeConfig } = useFlags();
  const pageSize = pageSizeConfig ?? 10;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const table = useReactTable({
    data: data,
    columns,
    filterFromLeafRows: true,
    initialState: {
      ...initialState,
    },
    state: {
      sorting,
      pagination,
    },
    globalFilterFn: "includesString",
    //@ts-ignore
    getSubRows: getSubRowsFn,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: Math.ceil(data.length / pageSize),
    initialState: { currentPage: 1 },
    limits: { outer: 2, inner: 2 },
  });

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack>
      {title && (
        <HStack>
          {onBackClick && (
            <IconButton
              aria-label="go back"
              icon={<ArrowLeft />}
              style={{ background: "transparent" }}
              onClick={onBackClick}
              color={"brand.accent"}
            />
          )}
          <Heading variant={"md"}>{title}</Heading>
        </HStack>
      )}
      {subtitle && (
        <Text fontWeight={"400"} variant={"sm"}>
          {subtitle}
        </Text>
      )}
      {(isSearchEnabled || actionChildren) && (
        <Box pb={5} mt={5}>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={isSearchEnabled ? "space-between" : "flex-end"}
          >
            {isSearchEnabled && (
              <InputGroup width={{ base: "100%", md: "30%" }}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} stroke="brand.accent" boxSize="4" />
                </InputLeftElement>
                <Input
                  borderColor={"#E2E8F0"}
                  borderWidth={2}
                  placeholder="Search"
                  value={table.getState().globalFilter}
                  onChange={(e: any) => {
                    table.setGlobalFilter(e.target.value);
                  }}
                />
              </InputGroup>
            )}
            <HStack width={{ base: "100%", md: "auto" }}>
              {actionChildren}
            </HStack>
          </Stack>
        </Box>
      )}
      <Box>
        {!isMobile && (
          <TableContainer border="2px solid #E2E8F0" borderRadius="md">
            <Table className={"responsiveTable"} variant={"simple"}>
              <Thead>
                {!suppressHeaderRow &&
                  table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index) => {
                        // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                        const meta: any = header.column.columnDef.meta;
                        return (
                          <Th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            isNumeric={meta?.isNumeric}
                            borderTop={5}
                            border={"none"}
                            bg={"brand.tintGray.200"}
                            borderTopLeftRadius={index === 0 ? "md" : undefined}
                            borderTopRightRadius={
                              index === headerGroup.headers.length - 1
                                ? "md"
                                : undefined
                            }
                          >
                            <HStack>
                              <Text
                                variant={"md"}
                                textTransform={"initial"}
                                color={"gray.900"}
                                mb={"0 !important"}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </Text>
                              <chakra.span pl="4" display={"inline-block"}>
                                {header.column.getIsSorted() ? (
                                  header.column.getIsSorted() === "desc" ? (
                                    <StatUpArrow aria-label="sorted descending" />
                                  ) : (
                                    <StatDownArrow aria-label="sorted ascending" />
                                  )
                                ) : null}
                              </chakra.span>
                            </HStack>
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row, rowIndex) => (
                  <Tr key={row.id} _hover={{ background: "gray.100" }}>
                    {row.getVisibleCells().map((cell, cellIndex) => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      const meta: any = cell.column.columnDef.meta;
                      return (
                        <Td
                          className="pivoted"
                          key={cell.id}
                          py={{ base: 2, md: 2 }}
                          isNumeric={meta?.isNumeric}
                          onClick={() =>
                            onRowClick && onRowClick(rowIndex, cellIndex)
                          }
                          borderBottomWidth={
                            table.getRowModel().rows.length - 1 === rowIndex
                              ? 0
                              : 2
                          }
                          borderColor={"#E2E8F0"}
                        >
                          <Box className={"tdBefore"} p={2}>
                            <Text
                              textTransform={"capitalize"}
                              color={"rgb(113,128,150)"}
                              pl={2}
                            >
                              {cell.column.id}
                            </Text>
                          </Box>
                          <Tooltip
                            label={`${
                              typeof cell.getValue() === "string"
                                ? cell.getValue()
                                : ""
                            }`}
                          >
                            <Text noOfLines={1} whiteSpace={"normal"}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Text>
                          </Tooltip>
                        </Td>
                      );
                    })}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {isMobile && (
          <Stack spacing={4}>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <Card
                variant={"outline"}
                onClick={() => onRowClick && onRowClick(rowIndex, 0)}
              >
                <CardBody>
                  {row.getVisibleCells().map((cell) => {
                    const meta: any = cell.column.columnDef.meta;
                    return (
                      <HStack key={cell.id} justifyContent="space-between">
                        <Text
                          variant={"xs"}
                          fontWeight="bold"
                          textTransform="capitalize"
                          color="gray.600"
                        >
                          {cell.column.id}:
                        </Text>
                        <Text variant={"xs"} fontWeight={"normal"}>
                          {/* @ts-ignore */}
                          {cell.getValue()}
                        </Text>
                      </HStack>
                    );
                  })}
                </CardBody>
              </Card>
            ))}
          </Stack>
        )}
        {!suppressPaginationFooter && (
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            // isDisabled={isDisabled}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer
              align="center"
              justify="space-between"
              mt={4}
              w="full"
            >
              <PaginationPrevious
                variant={"ghost"}
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
              >
                <HStack>
                  <ArrowLeft />
                  <Text>Previous</Text>
                </HStack>
              </PaginationPrevious>
              <PaginationPageGroup
                align="center"
                separator={
                  <PaginationSeparator
                    isDisabled
                    bg="brand.gray.200"
                    fontSize="sm"
                    w={7}
                    jumpSize={11}
                  />
                }
              >
                {pages.map((page: number) => (
                  <PaginationPage
                    w={7}
                    color={"brand.primary.500"}
                    key={`pagination_page_${page}`}
                    page={page}
                    onClick={() => table.setPageIndex(page - 1)}
                    fontSize="sm"
                    _hover={{
                      bg: "brand.gray.200",
                    }}
                    _current={{
                      bg: "brand.gray.200",
                      fontSize: "sm",
                      w: 10,
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                variant={"ghost"}
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}
              >
                <HStack>
                  <Text>Next</Text>
                  <ArrowRight />
                </HStack>
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        )}
      </Box>
    </Stack>
  );
}
