import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useOrganizationList, useOrganization } from "@clerk/clerk-react";
import { toast } from "react-toastify";

export const OrganizationDetailsSection: React.FC = () => {
  const orgnizationDetailsEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters.")
      .max(50, "Name must be less than 50 characters.")
      .required("Name is required."),
    slug: Yup.string()
      .min(3, "Slug must be at least 3 characters.")
      .max(50, "Slug must be less than 50 characters.")
      .matches(
        /^[a-z\-]*$/,
        "Only lowercase letters and dashes are allowed with no spaces."
      )
      .required("Slug is required."),
  });

  const { organization } = useOrganization();
  const { setActive, isLoaded, createOrganization } = useOrganizationList();

  const initialValues = {
    name: organization?.name ?? "",
    slug: organization?.slug ?? "",
  };

  const onSubmit = async (values: any) => {
    if (createOrganization && !organization) {
      try {
        const result = await createOrganization({
          name: values.name,
          slug: values.slug,
        });

        await setActive({ organization: result });
        toast("Organization created successfully.");
      } catch (exception: any) {
        if (exception.errors) {
          exception.errors.forEach((error: any) => {
            toast.error(error.message);
          });
        }
      }
    } else if (createOrganization && organization) {
      try {
        await organization.update({
          name: values.name,
          slug: values.slug,
        });

        toast("Organization updated successfully.");
      } catch (exception: any) {
        if (exception.errors) {
          exception.errors.forEach((error: any) => {
            toast.error(error.longMessage);
          });
        }
      }
    }
  };

  return (
    <Card
      direction={{ base: "row", sm: "row" }}
      variant="outline"
      my={{ base: "4", md: "8" }}
    >
      <Stack width={"100%"}>
        <Formik
          initialValues={initialValues}
          validationSchema={orgnizationDetailsEditSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, touched, setFieldValue }) => (
            <Form className="p-4 text-center">
              <CardBody>
                <Heading size="md">Organization Details</Heading>
                <Stack
                  spacing="5"
                  px={{ base: "2", md: "3" }}
                  py={{ base: "2", md: "3" }}
                >
                  <Stack color="brand.text">
                    <FormControl
                      isInvalid={!!errors.name && !!touched.name}
                      mb={5}
                    >
                      <FormLabel color="brand.text">Name</FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={async (e: any) => {
                          const { value } = e.target;

                          setFieldValue("name", value);
                          setFieldValue(
                            "slug",
                            value.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase()
                          );
                        }}
                      />
                      <FormErrorMessage>
                        <ErrorMessage name="name" />
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors.slug && !!touched.slug}
                      mb={5}
                    >
                      <FormLabel
                        color="brand.text"
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Tooltip
                          label="A slug is a human-readable ID that must be unique. It's often used in URLs."
                          hasArrow
                          arrowSize={15}
                        >
                          <HStack>
                            <Text>Slug URL</Text>
                            <BsFillQuestionCircleFill />
                          </HStack>
                        </Tooltip>
                      </FormLabel>

                      <Field
                        as={Input}
                        id="slug"
                        name="slug"
                        type="text"
                        value={values.slug}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        <ErrorMessage name="slug" />
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Stack>
              </CardBody>
              <CardFooter justifyContent={"right"} pt={0}>
                <Button
                  variant="outline"
                  bg="brand.primary.500"
                  color={"brand.textLight"}
                  _hover={{ backgroundColor: "brand.primary.500" }}
                  type="submit"
                >
                  Save
                </Button>
              </CardFooter>
            </Form>
          )}
        </Formik>
      </Stack>
    </Card>
  );
};
